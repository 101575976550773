import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import CssBaseline from "@material-ui/core/CssBaseline";
import { LocaleContextProvider } from './context/LocaleContext';


const common = {
  white: '#ffffff',
  black: '#000000',
};
const theme = createMuiTheme({

  shape: {
    borderRadius: '14px',
  },

  direction: 'ltr',

  palette: {

    type: 'light',

    primary: {
      main: 'rgba(0, 128, 43, 0.7)', //WtButton background color
    },

    secondary: {
      main: 'rgba(153, 0, 0, 0.7)'
    },

    //https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/createPalette.js

    // The colors used to style the text.
    text: {
      // The most important text.
      primary: 'rgba(0, 0, 0, 0.87)',
      // Secondary text.
      secondary: 'rgba(0, 0, 0, 0.54)',
      // Disabled text have even lower visual prominence.
      disabled: 'rgba(0, 0, 0, 0.38)',
      // Text hints.
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    // The color used to divide different elements.
    divider: 'rgba(0, 0, 0, 0.12)',
    // The background colors used to style the surfaces.
    // Consistency between these values is important.
    background: {
      paper: common.white,
      default: colors.grey[50],

      //pulSEnsum Backgrounds
      mainBG: 'linear-gradient(to right top, rgb(135, 250, 194, 0.5), rgb(135, 250, 194, 0.8))',
      // mainBG: 'linear-gradient(to right top, rgb(177, 225, 231, 0.5), rgb(157, 217, 225, 1))',

      glass: 'linear-gradient(to right bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.3))',

      formBG: 'linear-gradient(to left top, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.3))',

      eliteBG: 'linear-gradient(to left top, #dad8f2, #8987d4, #dad8f2 )',
      errorBG: 'linear-gradient(to left top, #bd3333, #cc6d62 65%, #7d281d)',
      infoBG: 'linear-gradient(to left top, #02ba02, #5cd15c 65%, #1c751c)',
      successBG: 'linear-gradient(to left top, #0202b8, #5959cf 65%, #1b1b75)',

      modalBottomBG: 'linear-gradient(to right bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.6))',
      modalBottomBGdark: 'linear-gradient(to left top, #f0eed5, #545353 )',

    },
    // The colors used to style the action elements.
    action: {
      // The color of an active action like an icon button.
      active: 'rgba(0, 0, 0, 0.54)',
      // The color of an hovered action.
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      // The color of a selected action.
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      // The color of a disabled action.
      // disabled: 'rgba(0, 0, 0, 0.26)',
      // The background color of a disabled action.
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,

      //Above there are all the original values. Below the ones that we overrides
      // disabledBackground: 'rgba(255, 184, 77, 0.7)', //'rgba(153, 51, 153, 0.35)', //'rgba(0, 0, 0, 0.32)',
      disabled: 'rgba(0, 0, 0, 0.56)',
    },

  }
});

ReactDOM.render(
  <BrowserRouter>
    <LocaleContextProvider>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </MuiThemeProvider>
    </LocaleContextProvider>
  </BrowserRouter>
  ,
  document.getElementById('root')
);
