import React from 'react';
import { MenuItem, InputAdornment } from '@material-ui/core';
import Flag from './Flags';
import { OCSelect, OCText } from './OCComponents';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import * as constants from './Constants';


export const DateText = props => {

    return (
        <FormattedDate
            value={props.date}
            year="numeric"
            month="long"
            day="2-digit"
            hour="2-digit"
            minute="2-digit"
            second="2-digit"
            {...props}
        />
    );

}

export const LocationText = props => {

    return (
        <FormattedMessage id={props.location}
            values={{
                flag: () => null,
                e: () => null
            }}>
        </FormattedMessage>
    );
}

export const LocationSelect = props => {

    const { formatMessage: f } = useIntl();

    const flag = lcle => {
        return <Flag countryCode={lcle[0]} style={{ width: '24px', height: '15px' }} />;
    };

    const emptyChars = () => {
        return '\u00A0\u00A0\u00A0';
    }

    let locations = [];
    if (props.all && props.all === "true")
        locations.push({ key: constants.SELECT_ALL_INTL });
    if (props.locations)
        locations = locations.concat(props.locations());

    const subProps = {...props};
    delete subProps.locations;
    delete subProps.changed;

    return (
        <OCSelect
            id="location"
            label={f({ id: 'select.location.label' })}
            onChange={props.changed}
            {...subProps}
        >
            {
                locations.map((loc, index) => {
                    // console.log("locations.map", JSON.stringify(loc), props);

                    return (
                        <MenuItem key={index} value={loc.key}>
                            <FormattedMessage id={loc.key}
                                values={{
                                    flag: c => flag(c),
                                    e: () => emptyChars()
                                }}>
                            </FormattedMessage>
                        </MenuItem>
                    );
                })
            }

        </OCSelect>
    )
};

export const UrlText = props => {

    const { formatMessage: f } = useIntl();

    return (
        <OCText
            id="url"
            variant="outlined"
            // helperText="Enter a valid Url"
            required
            InputProps={{
                startAdornment: <InputAdornment disablePointerEvents position="start">{props.protocol}</InputAdornment>,
            }}
            label={f({ id: "text.url.label" })}
            defaultValue=""
            placeholder="www.websensum.com"
            onChange={props.changed}
        />
    );
};
