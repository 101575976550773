import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

const OCStackedColumn = props => {

  // console.log('OCStackedColumn.render', props);
  const { categories, chartHeight } = props;

  let plotOptionsSeries = {};
  if (props.plotOptions && props.plotOptions.series)
    plotOptionsSeries = props.plotOptions.series;

  const options = {
    chart: {
      type: 'bar'
    },
    title: {
      text: props.title
    },
    subtitle: {
      text: props.subtitle
    },
    xAxis: {
      categories: categories
    },
    yAxis: {
      min: 0,
      title: {
        text: null
      }
    },
    legend: {
      reversed: true
    },
    credits: {
      href: null,
      text: '',
    },
    plotOptions: {
      ...props.plotOptions,
      series: {
        ...plotOptionsSeries,
        stacking: 'normal',
      },
    },
    series: props.series
  };

  const [height, setHeight] = useState(null);

  useEffect(() => {
    // console.log('OCStackedColumn useEffect height will be:', chartHeight);

    setHeight(chartHeight);
  }, []);


  // console.log('OCStackedColumn will return with height:', height);

  let chart = null;
  if (height) {
    chart = <HighchartsReact
      containerProps={{ style: { height: height, borderRadius: '25px', width: 'auto' } }}
      highcharts={Highcharts}
      options={options}
    />;
  }

  return (
    <React.Fragment>
      {chart}
    </React.Fragment>
  )
}

OCStackedColumn.propTypes = {
  categories : PropTypes.array.isRequired,
  series: PropTypes.array.isRequired,
  chartHeight: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,

  //optional
  plotOptions: PropTypes.object,

};


export default OCStackedColumn;

