import * as constants from './Constants';

export const toDateStr = date => {

  try {
    const dt = new Date(date)
    return new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false

    }).format(dt)
  } catch (error) {
    console.error('Invalid date value :', date);
    return ' -Invalid Date-'
  }
};

export const capitalizeText = text => {
  return text.trim().replace(/^\w/, (c) => c.toUpperCase())
}

export const toUrlStr = url => {
  const res = { protocol: '', url: '' };
  if (url) {
    let arr = url.split('://');
    if (arr.length === 1)
      res.url = arr[0]
    else if (arr.length === 2) {
      res.protocol = arr[0]
      res.url = arr[1]
    }
  }
  return res;
}

export const toFloatInt = (float) => {
  return Math.trunc(float);
}

export const toFloatStr = (float, fixed) => {
  if (!float)
    return '';
  if (!fixed)
    fixed = 0;

  const result = Number.parseFloat(float).toFixed(fixed);
  return result;
}

export const parseUrl = url => {

  const result = { protocol: null, noprotocol: null, domain: { main: null, domain: null }, paths: [], file: '*', url: url };

  let parUrl = url.trim();
  try {

    let arr = parUrl.split('://');

    if (arr.length > 1) {
      result.protocol = arr[0];
      result.noprotocol = arr[1];
    } else {
      result.noprotocol = arr[0];
    }

    arr = result.noprotocol.split('/');
    result.domain.domain = arr[0];
    result.domain.main = arr[0];

    //Bug for xxx.com.tr | xxx.co.uk
    /* const domainArr = arr[0].split('.');
    if (domainArr.length > 1)
      result.domain.main = domainArr[ domainArr.length-2];
    else
      result.domain.main = domainArr[ 0 ]; */

    //So crop some cases
    if (result.domain.main.toLowerCase().startsWith('www.')) {
      result.domain.main = result.domain.main.substring(4);
    }
    if (result.domain.main.includes('@')) {
      result.domain.main = result.domain.main.split('@')[1];
    }
    if (result.domain.main.includes(':')) {
      result.domain.main = result.domain.main.split(':')[0];
    }


    if (arr.length > 1) {
      arr.forEach((p, index) => {
        if (index > 0 && index < arr.length - 1) {
          result.paths.push(p);
        }
      });
      result.file = arr[arr.length - 1];
    } else {
      result.file = arr[0];
    }

  } catch (err) {
    console.error('Invalid url:' + url, err);
  }

  return result;

};


// hasSubProperty( obj, 'audits.network-requests.items' look for obj has the given obj that is not undefined)
export const hasSubProperty = (obj, subproperties) => {

  if (obj) {
    return subproperties.split('.').reduce((acc, currVal) => {
      if (acc.result) {
        if (acc.obj.hasOwnProperty(currVal)) {

          acc.obj = acc.obj[currVal];

        } else {
          acc.result = false;
        }
      }

      return acc;

    }, { obj: obj, result: true }).result;
  }
  return false;
}

export const backendReportStatus = response => {

  if (response.reportAnswer) {

    if (response.reportAnswer.status === constants.BACKEND_REPORT_STATUS_SUCCESS) {
      return { valid: true, status: true, result: response[constants.BACKEND_REPORT_REPORT] };
    } else {
      return { valid: true, status: false, result: response[constants.BACKEND_REPORT_ANSWER] };
    }
  }
  return { valid: false, status: false, result: response };
};

export const testScoreError = err => {
  return { error: err };
};

export const testHasError = test => {
  return hasSubProperty(test, 'scores.error');
};

export const testErrorStyle = (test, theme) => {
  if (testHasError(test))
    return { background: theme.palette.background.errorBG };
  else
    return {};
};

export const calcVariant = (smallScreen, v, v2) => {
  if (v2)
    return smallScreen ? v2 : v;
  else
    return smallScreen ? "caption" : v;
};

export const spaces = times => {
  if (times && times > 0) {
    let result = '';
    for (let i = 0; i < times; i++) {
      result += constants.SPACE;
    }
    return result;
  }
  return constants.SPACE;
}

export const locationTextMap = (intl, locations) => {

  return locations.map(o => o.key)
    .reduce((acc, currVal) => {
      const msg = intl.formatMessage({ id: currVal }, { flag: () => null, e: () => null });
      acc.set(currVal, msg);
      return acc;
    }, new Map());
};

export const locationText = (intl, location) => {
  if (!location)
    return null;
    
  return intl.formatMessage(
    { id: location },
    { flag: () => null, e: () => null }
  );
};

export const dateText = (intl, date) => {
  if (!date)
    return null;

  return intl.formatDate(
    date,
    {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    }
  );
};

export const intlText = (intl, id) => {
  return intl.formatMessage({ id: id });
};