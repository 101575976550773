import React from 'react';
import { Avatar, makeStyles, IconButton } from '@material-ui/core';
import deflag from '../assets/images/flags/DE.png';
import gbflag from '../assets/images/flags/GB.png';
import trflag from '../assets/images/flags/TR.png';
import nlflag from '../assets/images/flags/NL.png';
import frflag from '../assets/images/flags/FR.png';

import ReactCountryFlag from 'react-country-flag';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '6px',
    },
    countryFlag: {
    },

    svg: {
        background: '#ffffff',
        height: 'auto',
        //   borderRadius: 0,
        //   padding:'6px',
    },
    pngimg: {
        height: '15px',
        width: '26px',
        borderRadius: '0px',
    }

}));

const Flag = props => {

    const classes = useStyles();
    return <ReactCountryFlag 
                svg 
                className={classes.countryFlag}
                countryCode={props.countryCode.toUpperCase()}
                style={{
                    height: '15px',
                    width: '24px',
                }} 
                {...props}
                />;

};

export default Flag;


export const DEFlagButton = props => {
    const classes = useStyles();
    return (
        <IconButton onClick={props.clicked} className={classes.root}>
            <Avatar src={deflag} className={classes.pngimg} alt="Deutsch" />
        </IconButton>
    );
};
export const GBFlagButton = props => {
    const classes = useStyles();
    return (
        <IconButton onClick={props.clicked} className={classes.root}>
            <Avatar src={gbflag} className={classes.pngimg} alt="English" />
        </IconButton>
    );
};
export const TRFlagButton = props => {
    const classes = useStyles();
    return (
        <IconButton onClick={props.clicked} className={classes.root}>
            <Avatar src={trflag} className={classes.pngimg} alt="Türkçe" />
        </IconButton>
    );
};
export const FRFlagButton = props => {
    const classes = useStyles();
    return (
        <IconButton onClick={props.clicked} className={classes.root}>
            <Avatar src={frflag} className={classes.pngimg} alt="French" />
        </IconButton>
    );
};
export const NLFlagButton = props => {
    const classes = useStyles();
    return (
        <IconButton onClick={props.clicked} className={classes.root}>
            <Avatar src={nlflag} className={classes.pngimg} alt="Dutsch" />
        </IconButton>
    );
};


