import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, IconButton, useMediaQuery, Drawer, List,
  ListItem, ListItemIcon, ListItemText
}
  from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import { useHistory } from 'react-router-dom';
import * as constants from '../../common/Constants';

import NetworkCheckSharpIcon from '@material-ui/icons/NetworkCheckSharp';
import FavoriteIcon from '@material-ui/icons/Favorite';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';
import { OCSnackbar } from '../../common/OCComponents';

import { useIntl } from 'react-intl';
import * as utils from '../../common/UtilFunctions';
import LocaleContext from '../../context/LocaleContext';
import { /* DEFlagButton, FRFlagButton, */ GBFlagButton, NLFlagButton, TRFlagButton }
  from '../../common/Flags';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up('lg')]: {
      justifyContent: "space-around",
    },
    alignItems: "center"

  },

  left: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: theme.spacing(2),

    // border: "1px solid black",
  },

  middle: {

    flex: 1,
    display: "flex",
    // width: "50%",
    justifyContent: "center",
    alignItems: "center",
    // paddingBottom: theme.spacing(1),
    // border: "2px solid red",
  },

  bottomnav: {

  },

  link: {

  },

  right: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: 'center',
    // border: "1px solid black",
    paddingRight: theme.spacing(1),
  },

  disabledText: {
    color: theme.palette.text.disabled,
  },

  product: {

    margin: '0px 5px',
    position: 'relative',
    color: 'rgba(0, 128, 43, 1)',
    fontStyle: 'oblique',
  },

  drawer: {
    width: '200px',
  },

  flag: {
    background: '#ffffff',
    height: 'auto',
  }


}));

const Header = (props) => {
  // console.log('Header', props);

  const localeContext = useContext(LocaleContext);
  const classes = useStyles();
  const history = useHistory();
  const { formatMessage: f } = useIntl()

  const [value, setValue] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);


  const smallScreen = useMediaQuery('(max-width:600px)');

  const toggleDrawer = status => {
    setDrawerOpen(status);
  }

  const homeText = f({ id: 'header.home' });
  const perfText = f({ id: 'header.perf' });
  const hlthText = f({ id: 'header.health' });
  const visText = f({ id: 'header.vis' });


  const listItemClicked = (event, pathIndex) => {
    event.preventDefault();
    history.push(constants.NAVIGATION_PATHS[pathIndex].path);
  };

  /*   const pathname = history.location.pathname;
    if (pathname.startsWith("/report/"))
      console.log("history:" + history.location.pathname + " REPORT...");
    else if (pathname === '/' || pathname === '/home')
      alert("history:" + history.location.pathname + "HOME ***"); */


  const calcVariant = (variant, variantSmall) => {
    return utils.calcVariant(smallScreen, variant, variantSmall);
  }

  return (
    <div className={classes.root}>

      {snackbarOpen &&
        <OCSnackbar
          vertical='bottom'
          horizontal='center'
          onClose={() => setSnackbarOpen(false)}
          message={'WebSensum ' + constants.APP_VERSION}
        />
      }

      <div className={classes.left}>
        <Typography variant={calcVariant("h6", "body1")} className={classes.product}>
          WebSensum
          </Typography>
      </div>

      <div className={classes.middle}>
        {
          smallScreen
            ?
            <React.Fragment key='left'>
              <IconButton display='none' className={classes.menuButton} onClick={() => toggleDrawer(true)}
                color="inherit" aria-label="menu" >
                <MenuIcon />
              </IconButton>
              <Drawer anchor='left' open={drawerOpen} onClose={() => toggleDrawer(false)}>
                <div
                  className={classes.drawer}
                  role="presentation"
                  onClick={() => toggleDrawer(false)}
                  onKeyDown={() => toggleDrawer(false)}
                >
                  <List>
                    <ListItem button key='home' onClick={event => listItemClicked(event, constants.NAVIGATION_PAGES.home.pathsIndex)}>
                      <ListItemIcon><HomeIcon /></ListItemIcon>
                      <ListItemText primary={homeText} />
                    </ListItem>
                    <ListItem button key='perf' onClick={event => listItemClicked(event, constants.NAVIGATION_PAGES.historyperf.pathsIndex)}>
                      <ListItemIcon><NetworkCheckSharpIcon /></ListItemIcon>
                      <ListItemText primary={perfText} />
                    </ListItem>
                    <ListItem button key='health' onClick={event => listItemClicked(event, constants.NAVIGATION_PAGES.historyhealth.pathsIndex)}>
                      <ListItemIcon><FavoriteIcon /></ListItemIcon>
                      <ListItemText primary={hlthText} />
                    </ListItem>
                    <ListItem button key='vis' onClick={event => listItemClicked(event, constants.NAVIGATION_PAGES.historyvis.pathsIndex)}>
                      <ListItemIcon><VisibilityTwoToneIcon /></ListItemIcon>
                      <ListItemText primary={visText} />
                    </ListItem>

                  </List>
                </div>
              </Drawer>
            </React.Fragment>
            :
            <BottomNavigation
              className={classes.bottomnav}
              style={{ backgroundColor: 'inherit' }}
              color="primary"
              value={value}
              onChange={(event, newValue) => {
                // console.log('Header', newValue, constants.NAVIGATION_PATHS[newValue].path);

                setValue(newValue);
                history.push(constants.NAVIGATION_PATHS[newValue].path);
              }}
              showLabels
            >
              <BottomNavigationAction label={homeText} icon={<HomeIcon />} />
              <BottomNavigationAction label={perfText} icon={<NetworkCheckSharpIcon />} />
              <BottomNavigationAction label={hlthText} icon={<FavoriteIcon />} />
              <BottomNavigationAction label={visText} icon={<VisibilityTwoToneIcon />} />
              {/* <BottomNavigationAction label="Demo" icon={<SlideshowRoundedIcon />} /> */}
            </BottomNavigation>
        }
      </div>

      <div className={classes.right} >


        <NLFlagButton clicked={(event) => localeContext.switchToNL()} />
        <GBFlagButton clicked={(event) => localeContext.switchToEN()} />
        <TRFlagButton clicked={(event) => localeContext.switchToTR()} />
        {/* 
        <DEFlagButton clicked={(event) => changeLocale(event, locale_de)}/>
              <FRFlagButton clicked={(event) => changeLocale(event, locale_tr)}/>
               */}

        <IconButton onClick={() => setSnackbarOpen(true)}>
          <AccountCircleRoundedIcon color="primary" />
        </IconButton>

      </div>
    </div>
  );
};


export default Header;
