import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        /* '& .MuiSnackbar-root' : {
            background: theme.palette.background.eliteBG,
        },
        '& .MuiSnackbar-anchorOriginTopRight':{
            background: theme.palette.background.eliteBG,
        }, */
        '& .MuiSnackbarContent-root':{
            background: theme.palette.background.formBG,
            color: theme.palette.text.primary
        },
        '& .MuiSnackbarContent-message': {
            ...theme.typography.body1
        }
    },
}));

const OCSnackbar = props => {

    
    const classes = useStyles();
    
    const {vertical, horizontal, autoHideDuration} = props;
    let anchorOrigin = null;
    if (vertical && horizontal)
        anchorOrigin = { vertical: vertical, horizontal: horizontal};
    else 
        anchorOrigin = { vertical: 'bottom', horizontal: 'center'};

    let duration = 3000;
    if (autoHideDuration)
        duration = autoHideDuration;
    
    // console.log('OCSnackbar rendering, ', props, anchorOrigin);
    return (
        <div >
            <Snackbar 
                classes={{ root : classes.root}}
                open={true} 
                autoHideDuration={duration} 
                onClose={props.onClose} 
                anchorOrigin={anchorOrigin}
                message={props.message}
                />
                
        </div>
    );
};

export default OCSnackbar;
