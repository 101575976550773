import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';

const useButtonStyles = makeStyles(theme => ({

    button: {
        height: '100%',
        minHeight: '48px',
        minWidth: '100px',
        borderRadius: '12px',
        textTransform: 'capitalize',
    },
    miniButton: {
        height: '100%',
        minHeight: '32px',
        minWidth: '66px',
        borderRadius: '12px',
        textTransform: 'capitalize',
    },
    tabButton: {
        height: '100%',
        minHeight: '48px',
        // minWidth: '100px',
        borderRadius: '0px',

        '& .MuiButton-label': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },
    },
    linkButton: {
        borderRadius: '12px',
        height: "auto",
        width: "auto",
        border: "0px solid black",
        boxShadow: "0px 0px 5px 1px",
        textTransform: 'none',
        padding: '2px 0px',
        ...theme.typography["subtitle2"]
    },

}));



const OCButton = props => {

    const classes = useButtonStyles();
    const { type } = props;

    


    let buttonCls = classes.button;
    if (type === 'tabButton')
        buttonCls = classes.tabButton;
    else if (type === 'linkButton')
        buttonCls = classes.linkButton;
    else if (type === 'miniButton')
        buttonCls = classes.miniButton;

    return (
        <div className={props.className}>
            <Button
                color="primary"
                variant="contained"
                {...props}
                classes={{ root: buttonCls }}
            >{props.children}</Button>
        </div>
    );
};

export default OCButton;
