import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    TextField, FormControl,
    InputLabel, Select, Input, MenuItem, Checkbox, ListItemText
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useTextFieldStyles = makeStyles((theme) => ({
    root: {
        background: '#ffffff',
        borderRadius: '12px',
        // overflow: 'hidden',
        width: "100%",
        '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
            minWidth: '100px',
            '& fieldset': {
            },
            '&:hover fieldset': {
            },
            '&.Mui-focused fieldset': {
            },
        },
        '& .MuiInputLabel-outlined': {
            color: '#000000',
            fontWeight: 'bolder',
            textTransform: 'capitalize',
        }
    },
}));

export const OCText = props => {

    const classes = useTextFieldStyles();
    const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset", borderRadius: "12px", background: '#ffffff' };

    return (

        <FormControl style={props.containerstyle} fullWidth={true}>

            <TextField
                {...props}
                classes={{ root: classes.root }}
                inputProps={{ style: inputStyle }}
                variant='outlined'
            >{props.children}</TextField>

        </FormControl>
    );

};

export const OCSelect = props => {

    const classes = useTextFieldStyles();
    const inputStyle = {
        WebkitBoxShadow: "0 0 0 1000px white inset",
        borderRadius: "12px",
        background: '#ffffff',
    };

    return (
        <FormControl style={props.containerstyle} fullWidth={props.fullwidth ? true : false}>
            <TextField select
                // className={classes.root}
                classes={{ root: classes.root }}
                onChange={props.changed}
                variant="outlined"
                {...props}
                inputProps={{ style: inputStyle }}
            >{props.children}
            </TextField>
        </FormControl>
    );

};

const useMultiSelectStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 500,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const OCMultiSelect = props => {
    const classes = useMultiSelectStyles();
    const { items, itemName, label, handleChange } = props;

    return (
        <FormControl variant="outlined" className={classes.formControl} style={props.containerStyle}>
            <InputLabel id="multi-checkbox-label">{label}</InputLabel>
            <Select
                labelId="multi-checkbox-label"
                id="multi-checkbox"
                multiple
                value={itemName}
                onChange={handleChange}
                input={<Input />}
                renderValue={selected => selected.join(', ')}
                MenuProps={MenuProps}
            >
                {items.map((name) => (
                    <MenuItem key={name} value={name}>
                        <Checkbox checked={itemName.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const useAutoCompleteStyles = makeStyles((theme) => ({
    root: {
        background: '#ffffff',
        borderRadius: '12px',
        // overflow: 'hidden',
        width: "100%",
        '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
            minWidth: '100px',
            '& fieldset': {
            },
            '&:hover fieldset': {
            },
            '&.Mui-focused fieldset': {
            },
        },
        '& .MuiInputLabel-outlined': {
            color: '#000000',
            fontWeight: 'bolder',
            textTransform: 'capitalize',
        }
    },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const OCAutoComplete = props => {
    const classes = useAutoCompleteStyles();
    const { items, onChange, containerstyle } = props;

    return (
            <Autocomplete
                variant="outlined"
                multiple
                id="checkboxes-tags-demo"
                classes={{ root: classes.root }}
                limitTags={2}
                options={items}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                onChange={onChange}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option}
                    </React.Fragment>
                )}
                style={containerstyle}
                renderInput={(params) => {
                    return ( <TextField {...params} variant="outlined" label={props.label} placeholder={props.placeholder} /> );
                }}
                {...props}
            />
    );
};
