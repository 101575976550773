import React, { useState, useEffect } from 'react';

import FileTracer from '../Dashboard/FileTracer';
import LighthouseViewer from 'react-lighthouse-viewer';

import { makeStyles, useTheme } from "@material-ui/core/styles";

import {
    useLocation,
    useHistory
} from "react-router-dom";

import { Typography } from '@material-ui/core';

import * as constants from '../../common/Constants';
import * as utils from '../../common/UtilFunctions';
import * as lsUtils from '../../common/LSUtils';
import OCJsonViewer from '../../common/OCComponents/OCJsonViewer';
import { useIntl } from 'react-intl';
import { DateText, LocationText } from '../../common/InputUtils';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "91vh",
        // border: "3px solid red"

    },

    fileTracer: {
        width: "80%",
        maxWidth: "800px",
        height: "500px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: theme.spacing(5),
    },
    topInfo: {
        width: "95%",
        maxWidth: "1000px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        margin: theme.spacing(2, 0),

    },

    reportViewer: {
        width: "95%",
        maxWidth: "1000px",
        marginBottom: theme.spacing(2),
    },

    topRows: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    topColumns: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    top: {
        margin: theme.spacing(0, 2),
        minWidth: "85px",
        // border: "1px solid black",

    },

}));


// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const PerformanceReport = props => {
    // console.log("Performance Report", props);
    const classes = useStyles();
    const theme = useTheme();
    const intl = useIntl();

    const [reportJson, setReportJson] = useState(null);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    let query = useQuery();
    const qIndex = query.get("index");

    // console.log("Query index:", qIndex);

    const t = (code, defaultMsg) => {
        let defMsg = code;
        if (defaultMsg)
            defMsg = defaultMsg;
        return intl.formatMessage({ id: code, defaultMessage: defMsg });
    };


    useEffect(() => {
        const newData = lsUtils.getTestByIndex(constants.LS_PERFORMANCE, qIndex);
        // console.log('Performance Report useEffect newData', newData);

        setData(newData);
    }, [qIndex]);

    let history = useHistory();
    const clear = () => {
        history.push("/");
    }

    const updateReportJsonHandler = reportData => {

        const reportStatus = utils.backendReportStatus(reportData);

        if (reportStatus.valid && !reportStatus.status) {
            //fail
            setError(reportStatus.status);
            return;
        }
        setReportJson(reportStatus.result);
    };

    return (
        <div className={classes.root}>

            <p></p>

            {data &&
                (
                    <div className={classes.topInfo}>
                        <div className={classes.topRows}>
                            <div className={classes.topColumns}>
                                <Typography variant="subtitle2" className={classes.top} component="div">{data.url}
                                </Typography>
                            </div>
                            <div className={classes.topColumns} style={{ textTransform: 'capitalize' }}>
                                <Typography variant="subtitle2" className={classes.top} component="div">
                                    <LocationText location={data.location}/>
                                </Typography>
                            </div>
                            <div className={classes.topColumns}>
                                <Typography variant="subtitle2" className={classes.top} component="div">
                                    <DateText date={data.date} />
                                </Typography>
                            </div>
                        </div>
                    </div >
                )
            }



            {!reportJson && data && !error &&
                (
                    <div className={classes.fileTracer}>
                        <FileTracer
                            type={constants.FILETRACER_CALLER_PERF}
                            responseData={data}
                            firstTimeout={500}
                            intervalTime={10000}
                            modalColor={theme.palette.primary}
                            title={t("filetracer.toptitle")}
                            info={data.url}
                            message={t("filetracer.message.willretry")}
                            onClose={clear}
                            reportHandler={updateReportJsonHandler} />
                    </div>
                )
            }

            {
                !error && reportJson &&
                <div className={classes.reportViewer}>
                    {reportJson &&
                        <LighthouseViewer json={reportJson} />}
                </div>
            }
            {
                error && <div className={classes.error}><OCJsonViewer src={error} /></div>
            }


        </div >
    );
};

export default PerformanceReport;








