import { useReducer, useCallback } from 'react';

export const ST_SEND = 'SEND';
export const ST_ERROR = 'ERROR';
export const ST_RESPONSE = 'RESPONSE';


const initialState = {

    st: null,
    data: null,

    //Reusable params for requestor
    extra: null,
    identifier: null
};

const httpReducer = (curHttpState, action) => {
    const updatedWithSt = { ...curHttpState, st: action.type };
    switch (action.type) {
        case ST_SEND:
            return {
                ...updatedWithSt,
                identifier: action.identifier,
                extra: action.extra
            };
        case ST_ERROR:
            return {
                ...updatedWithSt,
                data: action.errorMessage,
                extra: action.extra
            };
        case ST_RESPONSE:
            return {
                ...updatedWithSt,
                data: action.responseData,
                extra: action.extra
            };
        case 'CLEAR':
            return initialState;
        default:
            throw new Error('Should not be reached!');
    }
};

const useHttp = () => {

    const [httpState, dispatchHttp] = useReducer(httpReducer, initialState);

    const clear = useCallback(() => {
        dispatchHttp({ type: 'CLEAR' });
    }, []);

    const sendRequest = useCallback((url, method, body, reqIdentifier, reqExtra) => {

        //Fetch Headers
        const fetchHeaders = new Headers();
        fetchHeaders.append('Content-Type', 'application/json');

        let options = { method: method, mode: 'cors', headers: fetchHeaders };
        if (body) {
            const bodyJson = JSON.stringify(body);
            options = { ...options, body: bodyJson };
        }

        dispatchHttp({ type: ST_SEND, identifier: reqIdentifier, extra: reqExtra });
        fetch(url, options)
            .then(
                response => {
                    if (!response.ok) {
                        console.log('Network response:', response);
                        throw new Error('Network response is not ok', response);
                    }

                    return response.json();
                }
            )
            .then(responseData => {
                dispatchHttp({
                    type: ST_RESPONSE,
                    responseData: responseData,
                    extra: reqExtra
                });
            })
            .catch(error => {

                console.error('Fetch operation error', error);

                //DEBUG CODE
                /* dispatchHttp( {
                    type: 'RESPONSE',
                    responseData: mockResponse,
                    extra: reqExtra
                });  */

                dispatchHttp({
                    type: ST_ERROR,
                    errorMessage: error,
                    extra: reqExtra
                });
            });
    }, []);


    return {
        st: httpState.st,
        data: httpState.data,
        sendRequest: sendRequest,
        reqExtra: httpState.extra,
        reqIdentifier: httpState.identifier,
        clear: clear
    };

};

export default useHttp;