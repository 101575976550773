import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import * as constants from '../Constants';
import { useIntl } from 'react-intl';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OCDialog = (props) => {
  const intl = useIntl();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { type, confirmHandler, handleClose, dialogOpen, dialogTitle, dialogContent } = props;

  let actionTexts = { confirm: "dialog.action.label.yes", cancel: "dialog.action.label.no" };
  if (type === constants.COMPONENT_OCDIALOG_TYPE_CONFIRMCANCEL) {
    actionTexts = { confirm: "dialog.action.label.confirm", cancel: "dialog.action.label.cancel" }
  } else if (type === constants.COMPONENT_OCDIALOG_TYPE_OKCANCEL) {
    actionTexts.confirm = "dialog.action.label.ok";
  }

  return (
      <Dialog
        fullScreen={fullScreen}
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        TransitionComponent={Transition}
      >
        <DialogTitle id="responsive-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={confirmHandler} color="primary" >
            {intl.formatMessage({ id:actionTexts.confirm})}
          </Button>
          <Button onClick={handleClose} color="secondary" autoFocus>
            {intl.formatMessage({ id:actionTexts.cancel})}
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default OCDialog;
