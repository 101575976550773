import React, { useCallback, useEffect, useState } from 'react';
import useHttp, { ST_SEND } from '../../hooks/http';
import useInterval from '../../hooks/interval';
import { /* ST_SEND, */ ST_ERROR, ST_RESPONSE } from '../../hooks/http';

import {
  Fade, Grid, Typography, CircularProgress,
  LinearProgress, Box, IconButton, useMediaQuery
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { OCButton, OCSnackbar, OCDetailedMuiAlert } from '../../common/OCComponents';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import * as constants from '../../common/Constants';
import * as utils from '../../common/UtilFunctions';
import * as lsUtils from '../../common/LSUtils';
import { useIntl } from 'react-intl';

const CircularProgressWithLabel = props => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div"
          color="textSecondary">{`${Math.round(props.value,)}%`}</Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexGrow: 1,
    width: "100%",
    height: "100%",
    // padding: theme.spacing(2, 6),
    border: "0px solid red",
  },

  fileTracer: {
    width: '100%',
    maxWidth: '800px',
    height: '100%',
    maxHeight: '700px',
    overflow: "hidden",
    background: theme.palette.background.glass,
    borderRadius: "20px",
    border: "1px solid black",
  },

  topPiece: {
    height: "35%",
  },
  bottomPiece: {
    height: "65%",
    background: theme.palette.background.modalBottomBG,
  },

  title: {
    marginTop: 0,
    // paddingTop: theme.spacing(2),
  },
  info: {
    margin: 0,
    // padding: theme.spacing(2, 0),
  },

  progressBar: {
    height: '20px',
    width: '75%',
  },

  message: {
    // padding: theme.spacing(2)
  },

  htext: {
    // marginTop: theme.spacing(1)
  },

  sleepProgress: {
  },

  detail: {
    padding: theme.spacing(0, 2),
  },
  action: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 4, 0, 0)
  }

}));



const FileTracer = React.memo(props => {

  const classes = useStyles();
  const { formatMessage : f } = useIntl();
  const smallScreen = useMediaQuery('(max-width:900px)');

  const [showDetail, setShowDetail] = useState(false);

  const [counter, setCounter] = useState(0);
  const [progress, setProgress] = useState(0);

  const [error, setError] = useState(null);


  const [waitingMessage, setWaitingMessage] = useState(props.message);
  const [retryCounter, setRetryCounter] = useState(1);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { type } = props;

  const {
    st,
    data,
    sendRequest,
    reqIdentifier,
    clear
  } = useHttp();

  const t = (code, defaultMsg) => {
        
    let defMsg = code;
    if (defaultMsg)
        defMsg = defaultMsg;
    return f({ id: code, defaultMessage: defMsg});
  };

  const { reportHandler, responseData, firstTimeout, intervalTime } = props;
  const inputDataFileName = responseData.filename;
  const inputDataUrl = responseData.url;

  const invalidRepContentMsg = t("filetracer.invalid.report");
  const somethingWrongMsg = t("somethingwrong");

  const errorScore = () => {
    return ({ error: somethingWrongMsg });
  }
  const createHealthScore = repData => {

    if (repData.total){
      try {
        return (
          {
            dnslookup: repData.dnsLookup,
            tcpconnection: repData.tcpConnection,
            tlshandshake: repData.tlsHandshake,
            firstbyte: repData.firstByte,
            contenttransfer: repData.contentTransfer,
            total: repData.total
          }
        );
      }catch( err ){
        console.err("Invalid report content");
        return utils.testScoreError( invalidRepContentMsg );
      }
    }

    return errorScore();

  }

  const createPerfScore = repData => {

    if (repData.categories) {

      try {

        const categories = repData.categories;
        return (
          {
            performance: categories.performance.score * 100,
            accessibility: categories.accessibility.score * 100,
            bestpractices: categories["best-practices"].score * 100,
            seo: categories.seo.score * 100,
            pwa: categories.pwa.score * 100
          }
        );
      } catch (err) {
        console.error("Invalid report content");
        return utils.testScoreError( invalidRepContentMsg );
      }
    }
    return createErrorScore();

  }

  const createErrorScore = (reportStatus) => {
    setError(reportStatus.result);
    return utils.testScoreError( reportStatus.result);
  }

  const createScore = (reportStatus, invalidFunction, successFunction, failFunction) => {

    if (!reportStatus.valid) {
      return invalidFunction();
    } else if (reportStatus.status) {
      return successFunction();
    } else {
      return failFunction();
    }
  }

  const updateScores = useCallback((reportData, inputDataUrl, inputDataFileName) => {
    if (type === constants.FILETRACER_CALLER_VIS)
      return;

    const reportStatus = utils.backendReportStatus(reportData);

    let lsType = null;
    let reportDataScores = {};

    switch (type) {

      case constants.FILETRACER_CALLER_HEALTH:
        lsType = constants.LS_HEALTH;
        reportDataScores = createScore(
          reportStatus,
          () => createHealthScore(reportData),
          () => createHealthScore(reportData.reportJson),
          () => createErrorScore(reportStatus)
        );
        break;

      case constants.FILETRACER_CALLER_PERF:
        lsType = constants.LS_PERFORMANCE;
        reportDataScores = createScore(
          reportStatus,
          () => createPerfScore(reportData),
          () => createPerfScore(reportData.reportJson),
          () => createErrorScore(reportStatus)
        );
        break;

      default:
        console.error("Invalid FILETRACER_CALLER type", type);
        return;
    }

    lsUtils.updateScores(lsType, inputDataUrl, inputDataFileName, reportDataScores);

  }, [type]);


  useEffect(() => {

    if (st === ST_RESPONSE && data && reqIdentifier === 'CHECK_URL_REPORT') {

      setWaitingMessage(t("filetracer.message.success"));

      //Let update localStorage for scores of the report
      updateScores(data, inputDataUrl, inputDataFileName);

      reportHandler(data);
    } else if (st === ST_ERROR && data && reqIdentifier === 'CHECK_URL_REPORT') {

      setSnackbarOpen(true);

      // setWaitingMessage('Request Failed : ' + data.message + '. Sleeping ...');
      setWaitingMessage(t("filetracer.message.willretry"));
    }

  }, [st, data, reqIdentifier, reportHandler, inputDataUrl, inputDataFileName, updateScores]);

  useEffect(() => {

    setTimeout(() =>
      sendRequest(
        inputDataFileName,
        'GET',
        null,
        'CHECK_URL_REPORT'
      ), firstTimeout);
  }, [sendRequest, inputDataFileName, firstTimeout]);

  useInterval(() => {

    console.log("FileTracer.useInterval", st, data);
    if (st === ST_ERROR) {

      clear();
      const newMessage = t("filetracer.message.retrying") +" (#" + retryCounter + ")...";
      setWaitingMessage(newMessage);
      setRetryCounter(retryCounter + 1);
      setCounter(0);
      setProgress(0);
      sendRequest(
        inputDataFileName,
        'GET',
        null,
        'CHECK_URL_REPORT'
      );
    }

  }, intervalTime);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCounter(prevCounter => prevCounter + 1);
      setProgress(prevProgress =>
        (prevProgress + (100000 / intervalTime)) > 100
          ? 100
          : (prevProgress + (100000 / intervalTime))
      );
    }, 1000);

    return () => {
      clearTimeout(timer);
    }
  }, [counter, intervalTime]);

  const toggleShowDetail = () => {
    setShowDetail(!showDetail);
  }

  const theme = useTheme();


  const modalColor = theme.palette.background.infoBG;

  const loading = st === ST_SEND;


  let errorModalContent = null;
  if (error) {
    errorModalContent = (

      <OCDetailedMuiAlert
        severity="error"
        onClose={props.onClose}
        title={t("filetracer.reportformat.error")}>
        {JSON.stringify(error)}
      </OCDetailedMuiAlert>
    );
  }

  return (
    <div className={classes.root}>

      {errorModalContent}


      {snackbarOpen &&
        <OCSnackbar
          onClose={() => setSnackbarOpen(false)}
          message={ t("filetracer.report.notready")}
        />
      }


      <Grid
        container
        direction="column"
        justify="space-evenly"
        alignItems="center"

        className={classes.fileTracer} style={{ background: modalColor }}>

        {/* Top Piece */}
        <Grid container
          justify="space-evenly"
          direction="column"
          alignItems="center"
          className={classes.topPiece}
          style={{ background: modalColor }}
        >

          <Grid item className={classes.title}>
            {
              smallScreen ?
                <Typography variant="h6" className={classes.htext}>{props.title}</Typography> :
                <Typography variant="h4" className={classes.htext}>{props.title}</Typography>


            }
          </Grid>
          <Grid item className={classes.info}>
            {
              smallScreen ?
                <Typography variant="subtitle2">{props.info}</Typography> :
                <Typography variant="subtitle1">{props.info}</Typography>
            }
          </Grid>

          <div className={classes.progressBar}>
            <Fade
              in={loading}
              style={{
                transitionDelay: loading ? '200ms' : '0ms',
              }}
              unmountOnExit
            >
              <LinearProgress style={{ width: '100%' }} />
            </Fade>
          </div>

        </Grid> {/* END Top Piece */}


        {/* Bottom Piece */}
        <Grid container
          justify="space-evenly"
          direction="column"
          alignItems="center"
          className={classes.bottomPiece}
        >

          <Grid item className={classes.message}>
            {
              smallScreen ?
                <Typography variant="subtitle2" className={classes.htext}>{waitingMessage}</Typography> :
                <Typography variant="subtitle1" className={classes.htext}>{waitingMessage}</Typography>
            }
          </Grid>

          <Grid item className={classes.sleepProgress} >
            <Fade
              in={!loading}
              style={{
                transitionDelay: !loading ? '200ms' : '0ms',
              }}
              unmountOnExit
            >
              <CircularProgressWithLabel size={smallScreen ? 36 : 48} value={progress} />
            </Fade>
          </Grid>

          <Grid item className={classes.detail}>
            <IconButton onClick={toggleShowDetail}>
              <ExpandMoreIcon style={smallScreen ? { height: '18px', width: '18px' } : { height: '24px', width: '24px' }} />
            </IconButton>
          </Grid>

          <Grid item className={classes.detail}>
            {showDetail &&
              (smallScreen ?
                <Typography variant="caption" className={classes.text}>{inputDataFileName}</Typography> :
                <Typography variant="body2" className={classes.text}>{inputDataFileName}</Typography>
              )
            }
          </Grid>

          <Grid container direction="column" alignItems="flex-end" >

            <Grid item className={classes.action}>
              <OCButton
                type={smallScreen ? 'miniButton' : 'button'}
                style={{ background: modalColor }}
                onClick={props.onClose}
              >{t("button.close")}
            </OCButton>
            </Grid>

          </Grid>
        </Grid>

      </Grid>
    </div>
  );
});

export default FileTracer;
