import React, { useState, useEffect } from 'react';

import FileTracer from '../Dashboard/FileTracer';

import { makeStyles, useTheme } from "@material-ui/core/styles";

import {
    useLocation,
    useHistory
} from "react-router-dom";

import * as utils from '../../common/UtilFunctions';
import * as lsUtils from '../../common/LSUtils';
import { useMediaQuery } from '@material-ui/core';
import { OCGraphChart } from '../../common/OCComponents';
import * as constants from '../../common/Constants';
import { graphTypes, getGraphNodeType, graphSizes } from '../../common/OCComponents';

// import the react-json-view component
// import ReactJson from 'react-json-view';
import ReactJson from '../../common/OCComponents/OCJsonViewer';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "91vh",
        // border: "3px solid red"

    },

    fileTracer: {
        width: "80%",
        maxWidth: "800px",
        height: "500px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: theme.spacing(5),
    },

    reportViewer: {
        // width: "95%",
        // maxWidth: "1000px",
        height: '100%',
        width: 'auto',
        marginBottom: theme.spacing(2),
    },

    chartContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },

    error: {
        background: theme.palette.background.errorBG
    },


}));


const calculateDuration = item => {
    const st = Number.parseFloat(item.startTime);
    const en = Number.parseFloat(item.endTime);

    if (isNaN(st) || isNaN(en))
        return 0;

    return en - st;
};
const calculateTransferSize = item => {
    const f = Number.parseFloat(item.transferSize);

    if (isNaN(f))
        return 0;

    return f;
};
const convert2ChartData = (items) => {

    const callFrameReducer = (acc, currVal) => {
        if (acc && acc.trim.length > 0)
            return acc;
        else
            return currVal;
    };
    const validItems = items.filter(item => {

        if (!item.initiator || !item.initiator.type)
            return false;

        const type = item.initiator.type;
        let parentUrl = null;
        if (type !== 'other') {

            parentUrl = item.initiator.url;
            if (!parentUrl) {
                try {
                    parentUrl = item.initiator.stack.callFrames.map(f => f.url).reduce(callFrameReducer);
                } catch (err2) {
                }
                if (!parentUrl) {
                    try {
                        parentUrl = item.initiator.stack.parent.callFrames.map(f => f.url).reduce(callFrameReducer);
                    } catch (err3) {
                    }
                }
            }

            if (!parentUrl) {
                console.error("Invalid item", item);
                return false;
            }
        }
        item['initiatorType'] = type;
        item['parentUrl'] = parentUrl;
        return true;
    });
    // console.warn("Items size:" + items.length + ", validItems size:" + validItems.length);

    const dataMap = new Map(),
        url2IdMap = new Map(),
        rootIdArr = [];

    let obj, maxLevel = 0;
    items.forEach((item, index) => {

        let parentId = null, myId = (index + 1).toString();
        if (item.initiatorType === 'other') {
            //root node
            obj = {
                id: myId,
                parentId: null,
                childrenIds: [],
                level: 0,
                type: graphTypes.root,
                initiatorType: item.initiatorType,
                url: item.url,
                parentUrl: item.parentUrl,
                scores: {
                    count: { me: 1, sub: 0, total: 0 },
                    duration: { me: calculateDuration(item), sub: 0, total: 0 },
                    transferSize: { me: calculateTransferSize(item), sub: 0, total: 0 },
                }
            };
            rootIdArr.push(myId);
        } else {

            parentId = url2IdMap.get(item.parentUrl);

            if (!parentId) {
                console.error('parentId is undefined for item', item);
                return;
            }

            myId = parentId.concat('.').concat(myId);

            let parent = dataMap.get(parentId);
            parent.childrenIds.push(myId);
            if (parent.type === graphTypes.leaf) { //Update parent type
                parent.type = getGraphNodeType(parent.graphSizes);
            }
            let level = dataMap.get(parentId).level + 1;
            maxLevel = Math.max(level, maxLevel);

            obj = {
                id: myId,
                parentId: parentId,
                childrenIds: [],
                level: level,
                type: graphTypes.leaf, //Default to leaf. If any child will come, this will be updated
                initiatorType: item.initiatorType,
                url: item.url,
                parentUrl: item.parentUrl,
                scores: {
                    count: { me: 1, sub: 0, total: 0 },
                    duration: { me: calculateDuration(item), sub: 0, total: 0 },
                    transferSize: { me: calculateTransferSize(item), sub: 0, total: 0 },
                }
            };
        }

        url2IdMap.set(obj.url, obj.id);
        dataMap.set(obj.id, obj)
    });

    // SCORE CALCULATION
    //1) Calculate all the parent nodes' childrenValues starting from leaf  until (exclude) root node

    for (let lvl = maxLevel; lvl >= 0; lvl--) {
        //for each level, calculate the sum of children's scores for scores.XXX.myValue
        // and update url with UtilFunctions.parseUrl
        dataMap.forEach((obj, key, map) => {
            if (obj.level === lvl) {

                //update url with UtilFunctions.parseUrl
                obj.url = utils.parseUrl(obj.url);

                const myScores = obj.scores;
                //update scores.total (s)
                myScores.duration.total = myScores.duration.me + myScores.duration.sub;
                myScores.transferSize.total = myScores.transferSize.me + myScores.transferSize.sub;
                myScores.count.total = myScores.count.me + myScores.count.sub;

                if (lvl !== 0) { // For Leafs and Branches , update parent scores
                    //update parent scores with mines
                    const parentScores = map.get(obj.parentId).scores;
                    parentScores.duration.sub += (myScores.duration.sub + myScores.duration.me);
                    parentScores.transferSize.sub += (myScores.transferSize.sub + myScores.transferSize.me);
                    parentScores.count.sub += (myScores.count.sub + myScores.count.me);
                }
            }
        });
    }

    return { dataMap: dataMap, rootIdArr: rootIdArr };
}

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const VisReport = props => {
    const classes = useStyles();
    const theme = useTheme();
    const intl = useIntl();
    const history = useHistory();

    const smallScreen = useMediaQuery('(max-width:600px)');
    const largeScreen = useMediaQuery('(min-width:1500px)');

    const [test, setTest] = useState(null);
    const [error, setError] = useState(null);

    let query = useQuery();
    const qIndex = query.get("index");

    const t = (code) => {
        return utils.intlText(intl, code);
    };

    useEffect(() => {
        const newData = lsUtils.getTestByIndex(constants.LS_VIS, qIndex);
        setTest(newData);
    }, [qIndex]);

    const [chartData, setChartData] = useState(null);

    const updateReportJsonHandler = reportData => {

        const reportStatus = utils.backendReportStatus(reportData);
        let scores;

        if (reportStatus.valid && !reportStatus.status) {
            setError(reportStatus.result);
            scores = utils.testScoreError(reportStatus.result);
        } else if (!utils.hasSubProperty(reportStatus.result, 'audits.network-requests.details.items')) {
            const err = t("filetracer.reportformat.error");
            console.error(err, reportData);
            setError(err);
            scores = utils.testScoreError(err);
        } else {
            const items = reportStatus.result.audits['network-requests'].details.items;

            const chartData = convert2ChartData(items);

            //update vis scores
            const rootObj = chartData.rootIdArr.map(rootId => chartData.dataMap.get(rootId)).reduce((acc, currVal) => {
                if (acc.childrenIds.length < currVal.childrenIds.length)
                    acc = currVal;

                return acc;
            });
            scores = lsUtils.createVisScore(rootObj.scores.duration.total,
                rootObj.scores.count.total, rootObj.scores.transferSize.total);

            //update chart data
            setChartData(chartData);
        }
        lsUtils.updateScores(constants.LS_VIS, test.url, test.filename, scores);
        // console.log('Score updated with ', scores);

    };

    const clear = () => {
        history.push(constants.NAVIGATION_PATHS[constants.NAVIGATION_PAGES.home.pathsIndex].path);
    }

    let chartStyle = { height: '98%', width: '98%' };
    let graphSize = graphSizes.normal;
    if (smallScreen) {
        chartStyle = { height: '100%', width: '100%' };
        graphSize = graphSizes.small;
    } else if (largeScreen) {
        graphSize = graphSizes.large;
    }

    return (
        <div className={classes.root}>

            { !chartData && test && !error &&
                (
                    <div className={classes.fileTracer}>
                        <FileTracer
                            type={constants.FILETRACER_CALLER_VIS}
                            responseData={test}
                            firstTimeout={500}
                            intervalTime={10000}
                            modalColor={theme.palette.primary}
                            title={t("filetracer.toptitle")}
                            info={test.url}
                            message={t("filetracer.message.willretry")}
                            onClose={clear}
                            reportHandler={updateReportJsonHandler} />
                    </div>
                )
            }

            { chartData && !error &&
                <div id="container" className={classes.chartContainer} style={chartStyle}>

                    <OCGraphChart
                        title={test.url}
                        subtitle={utils.locationText(intl, test.location) + " - " + utils.dateText(intl, test.date)}
                        size={graphSize}
                        sourceDataObj={chartData}
                    />

                </div>
            }
            {
                error && <div className={classes.error}><ReactJson src={error} /></div>
            }

        </div >
    );
};

export default VisReport;








