import React from 'react';

import { makeStyles } from "@material-ui/core/styles";
import * as ocUtils from '../../common/UtilFunctions';
import { useMediaQuery } from '@material-ui/core';
import OCGraphTest from '../../common/OCComponents/Charts/OCGraph_Test';
import { graphTypes, getGraphNodeType, graphSizes } from '../../common/OCComponents';



import mockItems from '../../assets/reports/milliyet_visBug_sLondon.json';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "91vh",
        // border: "3px solid red"

    },

    fileTracer: {
        width: "80%",
        maxWidth: "800px",
        height: "500px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: theme.spacing(5),
    },

    reportViewer: {
        // width: "95%",
        // maxWidth: "1000px",
        height: '100%',
        width: 'auto',
        marginBottom: theme.spacing(2),
    },

    chartContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center'
    }


}));


const calculateDuration = item => {
    const st = Number.parseFloat(item.startTime);
    const en = Number.parseFloat(item.endTime);

    if (isNaN(st) || isNaN(en))
        return 0;

    return en - st;
};
const calculateTransferSize = item => {
    const f = Number.parseFloat(item.transferSize);

    if (isNaN(f))
        return 0;

    return f;
};
const convert2ChartData = (items) => {

    const callFrameReducer = (acc, currVal) => {
        if (acc && acc.trim.length > 0)
            return acc;
        else
            return currVal;
    };
    const validItems = items.filter(item => {

        if (!item.initiator || !item.initiator.type)
            return false;

        const type = item.initiator.type;
        let parentUrl = null;
        if (type !== 'other') {

            parentUrl = item.initiator.url;
            if (!parentUrl) {
                try {
                    parentUrl = item.initiator.stack.callFrames.map(f => f.url).reduce(callFrameReducer);
                } catch (err2) {
                }
                if (!parentUrl) {
                    try {
                        parentUrl = item.initiator.stack.parent.callFrames.map(f => f.url).reduce(callFrameReducer);
                    } catch (err3) {
                    }
                }
            }

            if (!parentUrl) {
                console.error("Invalid item", item);
                return false;
            }
        }
        item['initiatorType'] = type;
        item['parentUrl'] = parentUrl;
        return true;
    });
    console.warn("Items size:" + items.length + ", validItems size:" + validItems.length);

    const dataMap = new Map(),
        url2IdMap = new Map(),
        rootIdArr = [];



    let obj, maxLevel = 0;
    items.forEach((item, index) => {

        let parentId = null, myId = (index + 1).toString();
        if (item.initiatorType === 'other') {
            //root node
            obj = {
                id: myId,
                parentId: null,
                childrenIds: [],
                level: 0,
                type: graphTypes.root,
                initiatorType: item.initiatorType,
                url: item.url,
                parentUrl: item.parentUrl,
                scores: {
                    count: { me: 1, sub: 0, total: 0 },
                    duration: { me: calculateDuration(item), sub: 0, total: 0 },
                    transferSize: { me: calculateTransferSize(item), sub: 0, total: 0 },
                }
            };
            rootIdArr.push(myId);
        } else {

            parentId = url2IdMap.get(item.parentUrl);

            if (!parentId) {
                console.error('parentId is undefined for item', item);
                return;
            }


            myId = parentId.concat('.').concat(myId);

            let parent = dataMap.get(parentId);
            parent.childrenIds.push(myId);
            if (parent.type === graphTypes.leaf) { //Update parent type
                parent.type = getGraphNodeType(parent.graphSizes);
            }
            let level = dataMap.get(parentId).level + 1;
            maxLevel = Math.max(level, maxLevel);

            obj = {
                id: myId,
                parentId: parentId,
                childrenIds: [],
                level: level,
                type: graphTypes.leaf, //Default to leaf. If any child will come, this will be updated
                initiatorType: item.initiatorType,
                url: item.url,
                parentUrl: item.parentUrl,
                scores: {
                    count: { me: 1, sub: 0, total: 0 },
                    duration: { me: calculateDuration(item), sub: 0, total: 0 },
                    transferSize: { me: calculateTransferSize(item), sub: 0, total: 0 },
                }
            };
        }

        url2IdMap.set(obj.url, obj.id);
        dataMap.set(obj.id, obj)
    });

    // SCORE CALCULATION
    //1) Calculate all the parent nodes' childrenValues starting from leaf  until (exclude) root node

    for (let lvl = maxLevel; lvl >= 0; lvl--) {
        //for each level, calculate the sum of children's scores for scores.XXX.myValue
        // and update url with UtilFunctions.parseUrl
        dataMap.forEach((obj, key, map) => {
            if (obj.level === lvl) {

                //update url with UtilFunctions.parseUrl
                obj.url = ocUtils.parseUrl(obj.url);

                const myScores = obj.scores;
                //update scores.total (s)
                myScores.duration.total = myScores.duration.me + myScores.duration.sub;
                myScores.transferSize.total = myScores.transferSize.me + myScores.transferSize.sub;
                myScores.count.total = myScores.count.me + myScores.count.sub;

                if (lvl !== 0) { // For Leafs and Branches , update parent scores
                    //update parent scores with mines
                    const parentScores = map.get(obj.parentId).scores;
                    parentScores.duration.sub += (myScores.duration.sub + myScores.duration.me);
                    parentScores.transferSize.sub += (myScores.transferSize.sub + myScores.transferSize.me);
                    parentScores.count.sub += (myScores.count.sub + myScores.count.me);
                }
            }
        });
    }


    return { dataMap: dataMap, rootIdArr: rootIdArr };
}


const mockChartData = () => {
    return convert2ChartData(mockItems.audits['network-requests'].details.items);
};


const VisReport = props => {
    const classes = useStyles();

    const smallScreen = useMediaQuery('(max-width:600px)');
    const largeScreen = useMediaQuery('(min-width:1500px)');

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //DEBUG CODE

    const sourceData = mockChartData();


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////


    let chartStyle = { height: '98%', width: '98%' };
    let graphSize = graphSizes.normal;
    if (smallScreen){
        chartStyle = { height: '100%', width: '100%' };
        graphSize = graphSizes.small;
    }else if (largeScreen){
        graphSize = graphSizes.large;
    }

    return (
        <div className={classes.root}>

            
                <div id="container" className={classes.chartContainer} style={chartStyle}>

                    <OCGraphTest
                        title={'Fake Report'}
                        subtitle={' *** '}
                        size={graphSize}
                        sourceDataObj={sourceData}
                    />

                </div>

        </div >
    );
};

export default VisReport;








