import React from 'react';

import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minWidth: "250px",
        minHeight: "250px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // border: "3px solid red"
        borderRadius: '25px',
        color: '#ffffff'
    }

}));

const OCJsonViewer = props => {
    const classes = useStyles();

    return (

        <div className={classes.root}>
            {JSON.stringify(props.src)}
        </div>
    );
};

export default OCJsonViewer;

