import React, { useState } from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NetworkCheckSharpIcon from '@material-ui/icons/NetworkCheckSharp';
import FavoriteIcon from '@material-ui/icons/Favorite';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';

import PerformanceCheck from './PerformanceCheck';
import HealthCheck from './HealthCheck';
import VisCheck from './VisCheck';
import { OCButton } from '../../common/OCComponents';
import { useHistory } from 'react-router-dom';
import * as constants from '../../common/Constants';
import * as utils from '../../common/UtilFunctions';
import { FormattedMessage, useIntl } from 'react-intl';
// import { Badge } from '@material-ui/core';

const useStyles = makeStyles(theme => {

    return {

        root: {
            flexGrow: 1,
            width: "100%",
            height: "91vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "0",
            padding: "0",
            justifyContent: "flex-start",
            // flexWrap: "nowrap",
        },

        top: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "80%",
            height: "20%",
            border: "0px solid black",

            marginTop: '80px',
            [theme.breakpoints.down('md')]: {
                marginTop: '60px',
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: '40px',
            },
            [theme.breakpoints.down('xs')]: {
                marginTop: '10px',
            },
        },

        content: {
            width: "40%",
            minHeight: "550px",
            [theme.breakpoints.down('xl')]: {
                width: '50%'
            },
            [theme.breakpoints.down('lg')]: {
                width: '66%'
            },
            [theme.breakpoints.down('md')]: {
                width: '75%'
            },
            [theme.breakpoints.down('sm')]: {
                width: '90%',
                minHeight: '450px',
            },
            [theme.breakpoints.down('xs')]: {
                width: '98%',
                minHeight: '350px',
            },

            // maxWidth: "1000px",
            marginBottom: theme.spacing(1),
            borderRadius: "25px",
            boxShadow: "0px 0px 10px 2px",
            border: "0px solid black",
            // overflow: "hidden",

            position: "relative",
            background: theme.palette.background.glass,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",

        },

        behind : {
            position: 'absolute',
            zIndex: -1,
            filter: 'blur(3px)',
            transform: 'translate(-50%, -50%)',
        },

        behindGlassLU: {
            top: '25%',
            left: '25%',
        },
        behindGlassRU: {
            top: '25%',
            left: '75%',
        },

        behindGlassLC: {
            top: '50%',
            left: '15%',
        },
        behindGlassC: {
            top: '50%',
            left: '50%',
        },
        behindGlassRC: {
            top: '50%',
            left: '85%',
        },
        behindGlassLD: {
            top: '75%',
            left: '25%',
        },
        behindGlassRD: {
            top: '75%',
            left: '75%',
        },

        topHeader: {
            height: "50%",
            [theme.breakpoints.down('xs')]: {
                height: 'auto',
            },

            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-start",
            border: "0px solid black",
        },
        topBody: {
            width: "100%",
            height: "50%",
            [theme.breakpoints.down('xs')]: {
                height: 'auto',
            },
            border: "0px solid black",
        },


        buttonGroup: {

            width: "100%",

            height: "140px",
            [theme.breakpoints.down('xl')]: {
                height: '120px'
            },
            [theme.breakpoints.down('lg')]: {
                height: '100px'
            },
            [theme.breakpoints.down('sm')]: {
                height: '80px'
            },
            [theme.breakpoints.down('xs')]: {
                height: '80px',
                ...theme.typography.caption
            },

            borderRadius: "25px 25px 10px 10px",
            // overflow: 'hidden',
            display: 'flex',
            justifyContent: 'space-evenly',
            boxShadow: 'none',
            alignItems: "strech",

        },

        tab: {
            width: "100%",
            margin: "0 0px",
            [theme.breakpoints.down('sm')]: {
                ...theme.typography.caption,
                fontSize: '0.7rem'
            },
        },

        tabContent: {
            width: '85%',
            [theme.breakpoints.down('sm')]: {
                ...theme.typography.caption,
                fontSize: '0.7rem',
                width: '95%',
            },
            [theme.breakpoints.down('xs')]: {
                ...theme.typography.caption,
                fontSize: '0.7rem',
                width: '100%',
            },
            height: '100%',
            borderRadius: '2px 2px 20px 20px',
        },

        initialLoad: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',

        },
        initialLoadHeader: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        initialLoadLine: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

        },

    };

});

const Dashboard = props => {

    // console.log('Dashboard', props);

    const classes = useStyles();
    const history = useHistory();
    const { formatMessage: f } = useIntl();
    const smallScreen = useMediaQuery('(max-width:900px)');

    const [test, setTest] = useState('network');

    // const [perfLimit, setPerfLimit] = useState(10);
    // const [healthLimit, setHealthLimit] = useState(10);
    // const [visLimit, setVisLimit] = useState(10);

    const perfReportHandler = (data) => {

        if (data.scores && data.scores.error) {
            history.push(
                constants.NAVIGATION_PATHS[constants.NAVIGATION_PAGES.historyperf.pathsIndex].path
            );
        }

        // Go to url /report/performance?index={data.historyIndex}
        history.push(
            constants.NAVIGATION_PATHS[constants.NAVIGATION_PAGES.reportperf.pathsIndex].path
            + "?index=" + data.historyIndex
        );
    };

    const healthReportHandler = (data) => {

        history.push(
            constants.NAVIGATION_PATHS[constants.NAVIGATION_PAGES.reporthealth.pathsIndex].path
            + "?index=" + data.historyIndex
        );
    };

    const visReportHandler = data => {
        history.push(
            constants.NAVIGATION_PATHS[constants.NAVIGATION_PAGES.reportvis.pathsIndex].path
            + "?index=" + data.historyIndex
        );
    }


    const size =  smallScreen ? '200px' : '300px';
    const iconProps = {
        color: "primary",
        style: {
            // background: "rgba(255,0,0,1)",
            height: size,
            width: size,
        }
    };

    const iconPropsSmall = {
        color: "primary",
        style: {
            height: "50px",
            width: "50px",
        }
    };

    let testIcon = null;
    let testIconSmall = null;
    // let testInfo = null;
    if (test === 'http') {
        // testInfo = httpInfo
        testIcon = <FavoriteIcon  {...iconProps} />
        testIconSmall = <FavoriteIcon {...iconPropsSmall} />
    }
    else if (test === 'vis') {
        // testInfo = visInfo
        testIcon = <VisibilityTwoToneIcon  {...iconProps} />
        testIconSmall = <VisibilityTwoToneIcon {...iconPropsSmall} />
    } else if (test === 'network') {
        // testInfo = networkInfo;
        testIcon = <NetworkCheckSharpIcon {...iconProps} />
        testIconSmall = <NetworkCheckSharpIcon {...iconPropsSmall} />
    }

    const topTitle = <FormattedMessage id="dashboard.toptitle" />;
    const free = <FormattedMessage id="free" />;
    const topInfo = <FormattedMessage id="dashboard.topinfo" />;

    const calcVariant = (variant, variantSmall) => {
        return utils.calcVariant(smallScreen, variant, variantSmall);
    };

    const clsGlass = (cls) => {
        return [classes.behind].concat(cls).join(' ');
    };

    return (
        <div className={classes.root}>

            <div className={classes.top}>
                <div className={classes.topHeader}>
                    <Typography variant={calcVariant("h3", "h6")} align="center" className={classes.motto}>
                        {topTitle}
                    </Typography>
                    <Typography variant={calcVariant("h6", "caption")} align="left" style={{ margin: '0 0 0 10px', color: "rgba(255,255,255,0.7)" }}>
                        {free}
                    </Typography>
                </div>
                <div className={classes.topBody}>
                    <Typography variant={calcVariant("subtitle1", "body2")} align="center" >
                        {topInfo}
                    </Typography>
                </div>
            </div>

            <div className={classes.content}>

                <div className={clsGlass(classes.behindGlassLU)}>
                    {testIconSmall}
                </div>
                <div className={clsGlass(classes.behindGlassRU)}>
                    {testIconSmall}
                </div>
                <div className={clsGlass(classes.behindGlassLC)}>
                    {testIconSmall}
                </div>
                <div className={clsGlass(classes.behindGlassC)}>
                    {testIcon}
                </div>
                <div className={clsGlass(classes.behindGlassRC)}>
                    {testIconSmall}
                </div>
                <div className={clsGlass(classes.behindGlassLD)}>
                    {testIconSmall}
                </div>
                <div className={clsGlass(classes.behindGlassRD)}>
                    {testIconSmall}
                </div>

                <div className={classes.buttonGroup} /* value={test} */>

                    <OCButton
                        style={{ borderRadius: '20px 0 0 0' }}
                        type={'tabButton'}
                        className={classes.tab}
                        disabled={test === 'network'}
                        endIcon={<NetworkCheckSharpIcon />}
                        onClick={() => { setTest('network') }}>{f({ id: "dashboard.tabbutton.perf" })}
                    </OCButton>
                    <OCButton
                        type={'tabButton'}
                        className={classes.tab}
                        disabled={test === 'http'}
                        endIcon={<FavoriteIcon />}
                        onClick={() => { setTest('http') }}>{f({ id: "dashboard.tabbutton.health" })}
                    </OCButton>
                    <OCButton
                        style={{ borderRadius: '0 20px 0 0' }}
                        type={'tabButton'}
                        className={classes.tab}
                        disabled={test === 'vis'}
                        endIcon={<VisibilityTwoToneIcon />}
                        onClick={() => { setTest('vis') }}>{f({ id: "dashboard.tabbutton.vis" })}
                    </OCButton>

                </div>


                <div className={classes.tabContent}>

                    {
                        (test === 'network') &&
                        <PerformanceCheck
                            checkUrl={constants.URL_PERFORMANCE_CHECK}
                            reportHandler={perfReportHandler} />
                    }
                    {
                        (test === 'http') &&
                        <HealthCheck
                            checkUrl={constants.URL_HEALTH_CHECK}
                            reportHandler={healthReportHandler} />
                    }
                    {
                        (test === 'vis') &&
                        <VisCheck
                            checkUrl={constants.URL_VIS_CHECK}
                            reportHandler={visReportHandler} />
                    }
                    {
                        test === null &&
                        <div className={classes.initialLoad}>
                            <div className={classes.initialLoadHeader} >
                                <Typography variant={calcVariant("h3", "h6")} component="div">
                                    <FormattedMessage id="dashboard.testtext" />
                                </Typography>
                            </div>
                            <div className={classes.initialLoadLines} >
                                <div className={classes.initialLoadLine} >
                                    <NetworkCheckSharpIcon color="primary" />
                                    {utils.spaces(2)}
                                    <Typography variant={calcVariant("h6", "body2")}>
                                        <FormattedMessage id="dashboard.tabbutton.perf" />
                                        {':' + utils.spaces(2)}
                                    </Typography>
                                    <Typography variant={calcVariant("body1")}>
                                        <FormattedMessage id="perfhistory.top.title" />
                                    </Typography>
                                </div>
                                <div className={classes.initialLoadLine} >
                                    <FavoriteIcon color="primary" />
                                    {utils.spaces(2)}
                                    <Typography variant={calcVariant("h6", "body2")}>
                                        <FormattedMessage id="dashboard.tabbutton.health" />
                                        {':' + utils.spaces(2)}
                                    </Typography>
                                    <Typography variant={calcVariant("body1")}>
                                        <FormattedMessage id="healthhistory.top.title" />
                                    </Typography>
                                </div>
                                <div className={classes.initialLoadLine} >
                                    <VisibilityTwoToneIcon color="primary" />
                                    {utils.spaces(2)}
                                    <Typography variant={calcVariant("h6", "body2")}>
                                        <FormattedMessage id="dashboard.tabbutton.vis" />
                                        {':' + utils.spaces(2)}
                                    </Typography>
                                    <Typography variant={calcVariant("body1")}>
                                        <FormattedMessage id="vishistory.top.title" />
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    }


                </div>

            </div>

        </div >
    );
};

export default Dashboard;