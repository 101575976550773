import * as constants from './Constants';

export const LS_INDEX_ATT_NAME = 'lsindex';
export const loadTestsFromLocalStorage = lsType => {
    const testsRaw = localStorage.getItem(lsType);
    let tests = null;
    if (!testsRaw) {
        localStorage.setItem(lsType, JSON.stringify([]));
        tests = [];
    } else {
        tests = JSON.parse(testsRaw);
    }
    tests.forEach( (t, index) => t[LS_INDEX_ATT_NAME]=index );
    return tests;
};

export const getTestByIndex = (lsType, ndx) => {

    const tests = loadTestsFromLocalStorage(lsType);
    return tests.filter( t => t[LS_INDEX_ATT_NAME] === parseInt(ndx))[0];
};

export const createVisScore = (duration, count, transferSize) => {
    return { duration: duration, count: count, transfersize: transferSize};
};


//scores = { performance: xx, accessibility:xx ...} => performance report
//scores = { dnslookup: xx, tcpconnection:xx ...} => health report
//scores = { duration:xx, count:xx, transfersize:xx } => vis report
//scores = { error: ' <error message> '} => ALL reports
export const updateScores = (lsType, url, filename, scores) => {

    const tests = loadTestsFromLocalStorage(lsType);
    const updateTest = tests.filter(t => t.url === url && t.filename === filename)[0];

    if (!updateTest) {
        tests.push({ filename: filename, url: url, date: new Date(), scores: scores });
    } else {
        updateTest['scores'] = scores;
    }

    localStorage.setItem(lsType, JSON.stringify(tests));
};

export const chartHeightByTestsLength = len => {

    let chartHeight = constants.STACKEDCOLUMN_HEIGHT.mini;
    if (len > 30)
        chartHeight = constants.STACKEDCOLUMN_HEIGHT.xlarge;
    else if (len > 20)
        chartHeight = constants.STACKEDCOLUMN_HEIGHT.large;
    else if (len > 10)
        chartHeight = constants.STACKEDCOLUMN_HEIGHT.medium;

    return chartHeight;
}