import React, { useState, useEffect } from 'react';

import FileTracer from '../Dashboard/FileTracer';
import { OCStackedColumnChart } from '../../common/OCComponents';

import { makeStyles, useTheme } from "@material-ui/core/styles";

import {
    useLocation,
    useHistory
} from "react-router-dom";

import * as utils from '../../common/UtilFunctions';
import * as lsUtils from '../../common/LSUtils';
import * as constants from '../../common/Constants';
import ReactJson from '../../common/OCComponents/OCJsonViewer';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "91vh",
        justifyContent: 'flex-start',
        // border: "3px solid red"

    },
    
    fileTracer: {
        width: "80%",
        marginTop: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: theme.spacing(2),
        },

        maxWidth: "800px",
        height: "550px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    
    reportViewer:{
        width: "95%",
        margin: theme.spacing(4,0),
    },

    error:{
        background: theme.palette.background.errorBG
    }
    
    
    
}));


// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const HealthReport = props => {
    const classes = useStyles();
    const theme = useTheme();
    const intl = useIntl();
    const history = useHistory();

    const [reportJson, setReportJson] = useState(null);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    

    let query = useQuery();
    const qIndex = query.get("index");

    const t = (code) => {
        return utils.intlText(intl, code);
    };

    useEffect(() => {
        const newData =  lsUtils.getTestByIndex(constants.LS_HEALTH, qIndex);
        setData(newData);
    }, [qIndex]);

    const clear = () => {
        history.push(constants.NAVIGATION_PATHS[constants.NAVIGATION_PAGES.home.pathsIndex].path);
    }

    const reportHandler = report => {

        const reportStatus = utils.backendReportStatus(report);

        if (reportStatus.valid && !reportStatus.status){
            setError(reportStatus.result);
            return;
        }
        setReportJson(reportStatus.result);
    }

    // console.log('HealthReport returns with error', error);
    return (
        <div className={classes.root}>

            {data &&
                (
                    <div className={classes.topInfo}>
                        <div className={classes.topRows}>
                            <div className={classes.topColumns}>
                            </div>
                            <div className={classes.topColumns}>
                            </div>
                            <div className={classes.topColumns}>
                            </div>
                        </div>
                    </div >
                )
            }

            {!reportJson && data && !error &&
                (
                    <div className={classes.fileTracer}>
                        <FileTracer
                            type={constants.FILETRACER_CALLER_HEALTH}
                            responseData={data}
                            firstTimeout={500}
                            intervalTime={10000}
                            modalColor={theme.palette.primary}
                            title={t("filetracer.toptitle")}
                            info={data.url}
                            message={t("filetracer.message.willretry")}
                            onClose={clear}
                            reportHandler={reportHandler} />
                    </div>
                )
            }

            <div className={classes.reportViewer}>
                {reportJson &&
                    <OCStackedColumnChart
                    title={data.url}
                    subtitle={ utils.locationText(intl, data.location) 
                    + ' - ' + utils.dateText(intl, data.date)}
                    chartHeight='300px'
                    series={
                        [{
                            name: t("healthhistory.chartlegend.dnslookup"),
                            data: [reportJson.dnsLookup]
                        }, {
                            name: t("healthhistory.chartlegend.tcpconnection"),
                            data: [reportJson.tcpConnection]
                        }, {
                            name: t("healthhistory.chartlegend.tlshandshake"),
                            data: [reportJson.tlsHandshake]
                        }, {
                            name: t("healthhistory.chartlegend.firstbyte"),
                            data: [reportJson.firstByte]
                        }, {
                            name: t("healthhistory.chartlegend.contenttransfer"),
                            data: [reportJson.contentTransfer]
                        }]
                    }
                    categories={
                        [ '' ]
                    }
                    />
                }
                {
                     error && <div className={classes.error}><ReactJson src={error}/></div>
                }
            </div>

        </div >
    );
};

export default HealthReport;








