import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle  } from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';
import { Backdrop } from '@material-ui/core';

const useMessageStyles = makeStyles((theme) => ({
    root: {

    },



    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


const LocalMuiAlert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const OCAlert = props => {
    const classes = useMessageStyles();
    return (
        <div>
            <Backdrop className={classes.backdrop} open={true} onClick={props.onClose}>
                <Alert severity={props.severity}>{props.children}</Alert>
            </Backdrop>
        </div>

    );
}

export const OCMuiAlert = props => {

    const classes = useMessageStyles();
    return (
        <div>
            <Backdrop className={classes.backdrop} open={true} onClick={props.onClose}>
                <LocalMuiAlert severity={props.severity}>{props.children}</LocalMuiAlert>
            </Backdrop>
        </div>

    );

};


export const OCDetailedAlert = props => {

    const classes = useMessageStyles();

    return (
        <div>
            <Backdrop className={classes.backdrop} open={true} onClick={props.onClose}>
                <Alert severity={props.severity}>
                    <AlertTitle>{props.title}</AlertTitle>
                    {props.children}
                </Alert>
            </Backdrop>
        </div>

    );
}

export const OCDetailedMuiAlert = props => {

    const classes = useMessageStyles();

    return (
        <div>
            <Backdrop className={classes.backdrop} open={true} onClick={props.onClose}>
                <LocalMuiAlert severity={props.severity}>
                    <AlertTitle>{props.title}</AlertTitle>
                    {props.children}
                </LocalMuiAlert>
            </Backdrop>
        </div>

    );
}


