// import React from 'react'; 
import { graphSizes } from './OCComponents';

export const APP_VERSION = "v02.07_02";

export const SPACE = '\u00A0';

//INTEGRATION URLS
// export const URL_PERFORMANCE_CHECK = 'https://lighthouseapigw-b8mfyqur.nw.gateway.dev/performancemetric';
export const URL_PERFORMANCE_CHECK = 'https://wsapilhgw-7uh2yysw.nw.gateway.dev/performancemetric';
export const URL_PERFORMANCE_CHECK_REQ = { lhtype: 'full' };

// export const URL_HEALTH_CHECK = 'https://healthapigateway-b8mfyqur.wl.gateway.dev/performancemetric';
export const URL_HEALTH_CHECK = 'https://wsapihegw-7uh2yysw.nw.gateway.dev/performancemetric';
export const URL_HEALTH_CHECK_REQ = {};

// export const URL_VIS_CHECK = 'https://lighthouseapigw-b8mfyqur.nw.gateway.dev/performancemetric';
export const URL_VIS_CHECK = 'https://wsapilhgw-7uh2yysw.nw.gateway.dev/performancemetric';
export const URL_VIS_CHECK_REQ = { lhtype: 'graph' };

//LOCALSTORAGE CONSTANTS
// *********************************
export const LS_PERFORMANCE = 'psPerfTests';
export const LS_HEALTH = 'psHealthTests';
export const LS_VIS = 'psVisTests';

//NAVIGATION CONSTANTS
// *********************************
export const NAVIGATION_PATHS = {
    // These are the ones related with the Header-Navigations ( index < 100)
    0: { path: '/home' },
    1: { path: '/history/perf' },
    2: { path: '/history/health' },
    3: { path: '/history/vis' },
    4: { path: '/demo' },

    // These are internal paths ( index > 100)
    101: { path: '/report/performance' },
    102: { path: '/report/health' },
    103: { path: '/report/vis' },
    111: { path: '/soon' },


};
export const NAVIGATION_PAGES = {
    home: { pathsIndex: 0 },
    historyperf: { pathsIndex: 1 },
    historyhealth: { pathsIndex: 2 },
    historyvis: { pathsIndex: 3 },
    demo: { pathsIndex: 4 },
    reportperf: { pathsIndex: 101 },
    reporthealth: { pathsIndex: 102 },
    reportvis: { pathsIndex: 103 },
    soon: { pathsIndex: 111 }
};

// HEADER NAVIGATIONS - SMALL SCREEN MENU DRAWER
// *********************************
export const NAVIGATION_HEADER_MENUITEMS = {
    home: NAVIGATION_PATHS[NAVIGATION_PAGES.home.pathsIndex].path,
    perf: NAVIGATION_PATHS[NAVIGATION_PAGES.historyperf.pathsIndex].path,
    hlth: NAVIGATION_PATHS[NAVIGATION_PAGES.historyhealth.pathsIndex].path,
    vis: NAVIGATION_PATHS[NAVIGATION_PAGES.historyvis.pathsIndex].path,
};


//SELECT - CONSTANTS
export const SELECT_ALL_INTL =  "select.all";

// PERFORMANCE CHECK LOCATIONS
export const LOCATIONS_PERFCHECK = [
    { key: "frankfurt"},
    { key: "london"},
    { key: "virginia"},
];

// HEALTH CHECK LOCATIONS
export const LOCATIONS_HEALTHCHECK = [
    { key: "frankfurt"},
    { key: "london"},
    { key: "virginia"},
];

// VIS CHECK LOCATIONS
export const LOCATIONS_VISCHECK = [
    { key: "frankfurt"},
    { key: "london"},
    { key: "virginia"},
];

// FILETRACER CALLERS
export const FILETRACER_CALLER_VIS = 'vis';
export const FILETRACER_CALLER_PERF = 'perf';
export const FILETRACER_CALLER_HEALTH = 'health';


// GRAPH CONSTANTS

export const GRAPH_RADIUS_MULTIPLIER = {};
GRAPH_RADIUS_MULTIPLIER[graphSizes.small] = 0.65;
GRAPH_RADIUS_MULTIPLIER[graphSizes.normal] = 1;
GRAPH_RADIUS_MULTIPLIER[graphSizes.large] = 2;

export const GRAPH_RADIUS_DECREMENTER = {};
GRAPH_RADIUS_DECREMENTER[graphSizes.small] = -20;
GRAPH_RADIUS_DECREMENTER[graphSizes.normal] = 0;
GRAPH_RADIUS_DECREMENTER[graphSizes.large] = 5;

// STACKEDCOLUMN CHART CONSTANTS
export const STACKEDCOLUMN_HEIGHT = { mini: '500px', medium: '600px', large: '750px', xlarge: '900px' };

// BACKEND CONSTANTS
export const BACKEND_REPORT_STATUS_SUCCESS = "success";
export const BACKEND_REPORT_STATUS_FAIL = "fail";
export const BACKEND_REPORT_ANSWER = "reportAnswer";
export const BACKEND_REPORT_REPORT = "reportJson";

//OCDIALOG COMPONENT CONSTANTS
export const COMPONENT_OCDIALOG_TYPE_OKCANCEL = 'ok';
export const COMPONENT_OCDIALOG_TYPE_CONFIRMCANCEL = 'confirm';
export const COMPONENT_OCDIALOG_TYPE_YESNO = 'yes';
