import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from './components/Layout/Header';

import DashboardPage from './containers/Dashboard/Dashboard';

//History Pages
import PerfHistoryPage from './containers/History/PerfHistory';
import HealthHistoryPage from './containers/History/HealthHistory';
import VisHistoryPage from './containers/History/VisHistory';

// Report Pages
import PerformanceReportPage from './containers/Reports/PerformanceReport';
import HealthReportPage from './containers/Reports/HealthReport';
import VisReportPage from './containers/Reports/VisReport';
import VisReport_TestPage from './containers/Reports/VisReport_Test';
import SoonPage from './containers/Soon';
// import DemoPage from './containers/Demo';

import { makeStyles } from '@material-ui/core/styles';
import * as constants from './common/Constants';

const useStyles = makeStyles(theme => ({

  page: {

    margin: "0",
    minHeight: "100vh",
    width: "100%",
    background: theme.palette.background.mainBG,
  },

  header: {
    margin: "0",
    width: "100%",
    height: "7vh",

    background: theme.palette.background.glass,
  },

  content: {
    position: 'relative',
    width: "100%",
    minHeight: "91vh",
  },

  footer: {
    position: 'sticky',
    height: "2vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.background.glass,
  }


}));

const App = () => {

  const [showLocale, setShowLocale] = useState(false);

  const classes = useStyles();

  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <Header showLocale={showLocale} showHandler={setShowLocale}/>
      </div>
      <main className={classes.content}>

        <Switch>
          {/* <Route path={constants.NAVIGATION_PATHS[constants.NAVIGATION_PAGES.home.pathsIndex].path} 
            component={DashboardPage} exact /> */}

          <Route path={constants.NAVIGATION_PATHS[constants.NAVIGATION_PAGES.historyperf.pathsIndex].path} component={PerfHistoryPage} exact />
          <Route path={constants.NAVIGATION_PATHS[constants.NAVIGATION_PAGES.historyhealth.pathsIndex].path} component={HealthHistoryPage} exact />
          <Route path={constants.NAVIGATION_PATHS[constants.NAVIGATION_PAGES.historyvis.pathsIndex].path} component={VisHistoryPage} exact />

          <Route path={constants.NAVIGATION_PATHS[constants.NAVIGATION_PAGES.reportperf.pathsIndex].path} component={PerformanceReportPage} exact />
          <Route path={constants.NAVIGATION_PATHS[constants.NAVIGATION_PAGES.reporthealth.pathsIndex].path} component={HealthReportPage} exact />
          <Route path={constants.NAVIGATION_PATHS[constants.NAVIGATION_PAGES.reportvis.pathsIndex].path} component={VisReportPage} exact />
          <Route path={constants.NAVIGATION_PATHS[constants.NAVIGATION_PAGES.soon.pathsIndex].path} component={SoonPage} exact />


          <Route path={"/test/visreport"} component={VisReport_TestPage} exact />
          {/* <Route path={constants.NAVIGATION_PATHS[constants.NAVIGATION_PAGES.demo.pathsIndex].path} component={DemoPage} exact /> */}

          {/* No match */}
          <Route path="/" component={DashboardPage}/>
        </Switch>
      </main>

      <div className={classes.footer}>
        <h6> Copyright </h6> <h5> @ ... </h5><h6>&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp; ... </h6>
      </div>


    </div>
  );
};


export default App;
