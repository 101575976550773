import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => {


    return {

        root: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'

        },

        content: {
            width: '100%',
            height: '90%',
            borderRadius: "25px",
            // boxShadow: "0px 0px 10px 2px",
            // border: "0px solid black",
            // overflow: "hidden",
            padding: theme.spacing(2),

            background: theme.palette.background.eliteBG,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

        },




    };

});

const Soon = props => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Typography variant="h4" align="center" className={classes.motto}> Coming Soon ... </Typography>
                {/* <Typography variant="h6" align="left" style={{ margin: '0 0 0 20px', color: "rgba(255,255,255,0.7)" }}>free</Typography> */}
            </div>
        </div >
    );
};

export default Soon;