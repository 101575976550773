import React from 'react';
import './OCGauge.css';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

    root: {

        //gauge_wrapper:first-of-type
        contain: 'none',
        display: 'flex',
        flexDirection: 'column',
        // padding: '2px 5px 2px 0px',
        position: 'relative',
        width: '100%',
        // background: '#ffffff',

        // border: '1px solid red',
    },

    lhgaugeArc: {
        //animation: 'none',
        fill: 'none',
        stroke: 'var(--circle-color)', // *** var NOT DEFINED !!!
        transformOrigin: '50% 50%',
    },

    /* lhHeaderContainer : {
        display: 'block',
        margin: '0 auto',
        position: 'relative',
      }, */

    /* Gauge */


    lhgauge: {
        strokeLinecap: 'round',
        // width: 'var(--gauge-circle-size)',
        // height: 'var(--gauge-circle-size)',
        width: 'var(--gauge-circle-size)',
        height: 'var(--gauge-circle-size)',
    },

    lhgaugeBase: {
        opacity: 0.1,
        stroke: 'var(--circle-background)',
    },

    lhgaugeSvgWrapper: {
        position: 'relative',
        height: 'var(--gauge-circle-size)',
    },

    lhgaugePercentage: {
        width: '100%',
        height: 'var(--gauge-circle-size)',
        position: 'absolute',
        fontFamily: 'var(--report-font-family-monospace)',
        fontSize: 'calc(var(--gauge-circle-size) * 0.34 + 1.3px)',
        lineHeight: 0,
        textAlign: 'center',
        top: 'calc(var(--score-container-padding) + var(--gauge-circle-size) / 2)',
    },

    lhgaugeWrapper: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textDecoration: 'none',
        padding: 'var(--score-container-padding)',

        transitionLength: '1s',

        /* Contain the layout style paint & layers during animation*/
        contain: 'content',
        willChange: 'opacity',
        /* Only using for layer promotion */
    },

    lhgaugeLabel: {
        fontSize: 'var(--gauge-label-font-size)',
        lineHeight: 'var(--gauge-label-line-height)',
        // marginTop: '10px', //We dont use label, so to center the gauge, removed this padding
        textAlign: 'center',
        color: 'var(--report-text-color)',
    },

    /*
    lhscoresHeader lhgaugeWrapper,
    lhscoresHeader lhgaugePwaWrapper : {
      width: var(--gauge-wrapper-width)',
    },
    */


    lhgaugeWrapperPass: {
        color: 'var(--color-pass)',
        fill: 'var(--color-pass)',
        stroke: 'var(--color-pass)',
    },

    lhgaugeWrapperAverage: {
        color: 'var(--color-average)',
        fill: 'var(--color-average)',
        stroke: 'var(--color-average)',
    },

    lhgaugeWrapperFail: {
        color: 'var(--color-fail)',
        fill: 'var(--color-fail)',
        stroke: 'var(--color-fail)',
    },

    lhgaugeWrapperNotApplicable: {
        color: 'var(--color-not-applicable)',
        fill: 'var(--color-not-applicable)',
        stroke: 'var(--color-not-applicable)',
    },



}));




const OCGauge = props => {
    const r = "56";
    const strokeWidth= "8";

    const getGaugeArcStyle = () => {
        const retStyle = { };
        const percent = props.score / 100;

        const circumferencePx = 2 * Math.PI * Number(r);
        // The rounded linecap of the stroke extends the arc past its start and end.
        // First, we tweak the -90deg rotation to start exactly at the top of the circle.
        const strokeWidthPx = Number(strokeWidth);
        const rotationalAdjustmentPercent = 0.25 * strokeWidthPx / circumferencePx;
        retStyle.transform = `rotate(${-90 + rotationalAdjustmentPercent * 360}deg)`;

        // Then, we terminate the line a little early as well.
        let arcLengthPx = percent * circumferencePx - strokeWidthPx / 2;
        // Special cases. No dot for 0, and full ring if 100
        //if (percent === 0) arcElem.style.opacity = '0';
        if (percent === 1) arcLengthPx = circumferencePx;

        retStyle.strokeDasharray = `${Math.max(arcLengthPx, 0)} ${circumferencePx}`;

        return retStyle;
    };

    const classes = useStyles();
    
    const arcStyle = getGaugeArcStyle();
    
    let score = props.score;
    const scoreCls = [classes.lhgaugeWrapper];
    /* if (score < 0 || score > 100){
        score = null
        scoreCls.concat(classes.lhgaugeWrapperNotApplicable);
    }
    else */ if (score < 50) {
        scoreCls.push(classes.lhgaugeWrapperFail);
    }
    else if (score > 90) {
        scoreCls.push(classes.lhgaugeWrapperPass);
    }
    else { //if (score > 50 && score < 90)
        scoreCls.push(classes.lhgaugeWrapperAverage);
    }

    //size = small
    let clsx = ['lh-vars', 'lh-sticky-header', classes.root].join(' ');
    if (props.size && props.size === 'medium'){
        clsx = ['lh-vars2', classes.root].join(' ');
    }

    return (
        <div className={clsx}>

            <div className={scoreCls.join(' ')}>

                {/* Wrapper exists for the ::before plugin icon. Cannot create pseudo-elements on svgs. */}
                <div className={classes.lhgaugeSvgWrapper}>
                    <svg viewBox="0 0 120 120" className={classes.lhgauge}>
                        <circle className={classes.lhgaugeBase} r={r} cx="60" cy="60" strokeWidth={strokeWidth}></circle>
                        <circle className={classes.lhgaugeArc} r={r} cx="60" cy="60" strokeWidth={strokeWidth}
                            style={arcStyle}
                            /* style={{ transform: 'rotate(-87.9537deg)', strokeDasharray: '207.115, 351.858' }} */
                            ></circle>
                    </svg>
                </div>
                <div className={classes.lhgaugePercentage}>{props.score.toFixed(0)}</div>

                {/* should likely be an h2 */}
                <div className={classes.lhgaugeLabel}>{props.name}</div>
            </div>
        </div>
    );
};

export default OCGauge;