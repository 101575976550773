import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import * as utils from '../../common/UtilFunctions';
import * as lsUtils from '../../common/LSUtils';
import {
    Typography, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, IconButton, useMediaQuery, MenuItem, Tooltip
} from '@material-ui/core';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import * as constants from '../../common/Constants';
import { OCSelect, OCStackedColumnChart, OCAutoComplete } from '../../common/OCComponents';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateText, LocationSelect } from '../../common/InputUtils';
import { Link } from 'react-router-dom';
import OCDialog from '../../common/OCComponents/OCDialog';

const useTableStyles = makeStyles(theme => ({

    root: {
        flexGrow: 1,
        minHeight: "91vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0",
        padding: "0",
        width: "100%",
        justifyContent: "flex-start",
        flexWrap: "nowrap"
    },

    top: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "80%",
        height: "20%",
        minHeight: '200px',
        border: "0px solid black",

        // maxWidth: "1000px",
    },

    filter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',

        height: 'auto',
        padding: '0 4px 12px 4px',
        width: "85%",
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '95%'
        },

        marginBottom: theme.spacing(1),
        borderRadius: "12px",
        boxShadow: "0px 0px 10px 2px",
        border: "0px solid red",
        overflow: "hidden",
        background: theme.palette.background.formBG,
    },
    filterHeader: {
        height: '20px',
        width: "100%",
        fontWeight: "bolder",
        position: "relative",
        left: "-1px",
        color: "#aaaaaa",
    },
    filterDetail: {
        marginBottom: theme.spacing(3),
    },

    chartContainer: {
        width: "85%",
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
        margin: '0 auto 50px auto',

    },

    content: {

        width: "50%",
        [theme.breakpoints.down('xl')]: {
            width: '60%'
        },
        [theme.breakpoints.down('lg')]: {
            width: '76%'
        },
        [theme.breakpoints.down('md')]: {
            width: '85%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '98%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },

        marginBottom: theme.spacing(3),
        borderRadius: "12px",
        boxShadow: "0px 0px 10px 2px",
        border: "0px solid red",
        overflow: "hidden",
    },

    topHeader: {
        height: "50%",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        border: "0px solid black",
    },
    topBody: {
        height: "50%",
        border: "0px solid black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    historyDataTable: {

    },

    tableRow: {
        height: "70px",
        [theme.breakpoints.down('sm')]: {
            height: '60px'
        },
        [theme.breakpoints.down('xs')]: {
            height: '52px'
        },
    },

    score: {
        // display: "flex",
        // flexDirection: "row",
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        border: "0px solid black",
        height: "60px",
        backgroundColor: 'rgb(0, 51, 26, 0.6)',
        color: theme.palette.common.white,
        fontWeight: "bolder",
    },
    body: {
        border: "0px solid black",
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({

    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgb(255, 255, 255, 0.65)',
        },
        '&:nth-of-type(even)': {
            backgroundColor: 'rgb(255, 255, 255, 0.35)',
        },
        '& .MuiTableCell-root': {
            padding: '0px',
        },
        '& .MuiTableCell-sizeSmall': {
            padding: '0px',
        },

    },
}))(TableRow);


const VisHistory = props => {

    const theme = useTheme();
    const classes = useTableStyles();
    const intl = useIntl();
    const smallScreen = useMediaQuery('(max-width:900px)');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTest, setDialogTest] = useState(null);

    const [tests, setTests] = useState([]);
    const [options, setOptions] = useState(null);

    const [protocol, setProtocol] = useState(constants.SELECT_ALL_INTL);
    const [location, setLocation] = useState(constants.SELECT_ALL_INTL);
    const [multiUrl, setMultiUrl] = useState([]);

    const [filtered, setFiltered] = useState(false);

    const loadTestsFromLocalStorage = () => {
        return lsUtils.loadTestsFromLocalStorage(constants.LS_VIS);
    }

    const t = (code, defaultMsg) => {

        let defMsg = code;
        if (defaultMsg)
            defMsg = defaultMsg;
        return intl.formatMessage({ id: code, defaultMessage: defMsg });
    };

    const intlLocationMap = utils.locationTextMap(intl, constants.LOCATIONS_VISCHECK);

    useEffect(() => {
        // console.log('VisHistory useEffect: setTests');
        setTests(loadTestsFromLocalStorage());
    }, []);

    useEffect(() => {
        // console.log('VishHistory useEffect tests:', tests);

        const options = {
            /* chart: {
                events: {
                    load: function (event) {
                        console.log('VisHistory chart.events.load', event)
                    },
                },
            }, */
            plotOptions:
            {
                series: {
                    events: {
                        legendItemClick: (event) => {
                            const series = event.target.chart.series;
                            const clickedItem = event.target.legendItem;

                            series.forEach((s, i) => {

                                if (s.legendItem.textStr === clickedItem.textStr)
                                    s.show();
                                else
                                    s.hide();
                            });
                            event.preventDefault();
                        }
                    }
                }
            },
            categories: [],
            series:
                [
                    { name: t("vishistory.chartlegend.count"), data: [], visible: false },
                    { name: t("vishistory.chartlegend.transfersize"), data: [], visible: false },
                    { name: t("vishistory.chartlegend.duration"), data: [], visible: true },
                ],
        };

        let tmp, prot;
        // fill data
        tests.filter(tst => !utils.testHasError(tst)).forEach((tst) => {

            if (tst.scores) {
                //categories
                tmp = utils.parseUrl(tst.url);
                prot = tmp.protocol === 'https' ? '<b>https </b>' : '';

                options.categories.push(prot + tmp.domain.main + '-' + intlLocationMap.get(tst.location));
                //totalchart series
                options.series[0].data.push(utils.toFloatInt(tst.scores.count));
                options.series[1].data.push(utils.toFloatInt(tst.scores.transfersize));
                options.series[2].data.push(utils.toFloatInt(tst.scores.duration));
            }
        });
        setOptions(options);

    }, [tests]);

    useEffect(() => {
        // console.log('useEffect for Filter', location, protocol);
        let newTests = loadTestsFromLocalStorage();

        if (newTests.length === 0)
            return;

        if (location !== constants.SELECT_ALL_INTL)
            newTests = newTests.filter(t => t.location === location);
        if (protocol !== constants.SELECT_ALL_INTL)
            newTests = newTests.filter(t => {
                // console.log(t.url, utils.parseUrl(t.url).protocol, protocol);

                return utils.parseUrl(t.url).protocol === protocol;
            });
        if (multiUrl.length !== 0)
            newTests = newTests.filter(t => multiUrl.includes(utils.parseUrl(t.url).noprotocol));

        setTests(newTests);
        setFiltered(true);

    }, [location, protocol, multiUrl]);

    const deleteHandler = (test, event) => {

        event.preventDefault();

        //Update tests array by filtering and then update localStorage of the item localStorage
        const newTests = tests.filter(t => t.filename !== test.filename || t.url !== test.url || t.date !== test.date);
        localStorage.setItem(constants.LS_VIS, JSON.stringify(newTests));
        setTests(newTests);
        setDialogOpen(false);
        setDialogTest(null);
    };

    const filterHandler = (comp, event, value) => {
        // console.log('VisHistory filterHandler', comp, event);
        event.preventDefault();

        if (comp === 'location') {
            setLocation(event.target.value);
        } else if (comp === 'protocol') {
            setProtocol(event.target.value);
        } else if (comp === 'multiUrl') {
            setMultiUrl(value);
        }
    };

    const autocompleteChangeHandler = (comp, event, value, reason) => {
        // console.log('AutoCompleteHandler ', comp, event, value, reason);
        filterHandler(comp, event, value);
    };

    const calcVariant = (v, v2) => {
        if (v2)
            return smallScreen ? v2 : v;
        else
            return smallScreen ? "caption" : v;
    };

    if (tests.length === 0 && !filtered)

        return (
            <div className={classes.root}>
                <div className={classes.top}>
                    <div className={classes.topHeader}>
                        <Typography variant={calcVariant("h4", "h6")} align="center" >
                            <FormattedMessage id="history.notests" />
                        </Typography>
                    </div>
                </div>
            </div>
        );

    const scoreVrnt = calcVariant("subtitle2");
    const scoresTableCells = test => {

        if (test.scores && !utils.testHasError(test)) {

            return (
                <React.Fragment>
                    <StyledTableCell align="right">
                        <Typography variant={scoreVrnt} className={classes.score}>
                            {utils.toFloatStr(test.scores.duration, 0)}
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                        <Typography variant={scoreVrnt} className={classes.score}>
                            {utils.toFloatStr(test.scores.transfersize, 0)}
                        </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                        <Typography variant={scoreVrnt} className={classes.score}>
                            {test.scores.count}
                        </Typography>
                    </StyledTableCell>
                </React.Fragment>

            );
        }
        else {
            return (
                <React.Fragment>
                    <StyledTableCell align="center">
                        <div className={classes.score} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                        <div className={classes.score} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                        <div className={classes.score} />
                    </StyledTableCell>
                </React.Fragment>
            );
        }

    };

    const lstests = loadTestsFromLocalStorage();
    const chartHeight = lsUtils.chartHeightByTestsLength(lstests.length);

    const multiUrlItems =
        Array.from(
            lstests
                .filter(t => !utils.testHasError(t))
                .map(t => utils.parseUrl(t.url).noprotocol)
                .reduce((acc, curVal) => { acc.add(curVal); return acc; }, new Set())
        );

    // console.log('VisHistory will return with chartHeight & options:', chartHeight, options);

    const calcUrlText = test => {

        const urlObj = utils.parseUrl(test.url);
        const hrf = constants.NAVIGATION_PATHS[constants.NAVIGATION_PAGES.reportvis.pathsIndex].path
            + '?index=' + test[lsUtils.LS_INDEX_ATT_NAME];

        let urlText = (
            <Typography variant="body2">
                <Link to={hrf}>
                    {urlObj.url}
                </Link>
            </Typography>
        );

        if (smallScreen) {

            urlText = (
                <Typography variant="caption">
                    { urlObj.protocol}< br />
                    <Link to={hrf}>
                        {urlObj.domain.main}
                    </Link>
                </Typography>
            )
        }

        return urlText;
    };

    const deleteIconClickedHandler = (test, event) => {
        event.preventDefault();
        setDialogOpen(true);
        setDialogTest(test);
    };

    return (

        <div className={classes.root}>

            <div className={classes.top}>
                <div className={classes.topHeader}>
                    <Typography variant={calcVariant("h4", "subtitle1")} align="center" >
                        <FormattedMessage id="vishistory.top.title" />
                    </Typography>
                </div>
                <div className={classes.topBody}>
                    <Typography variant={calcVariant("subtitle1", "body2")} align="center" >
                        <FormattedMessage id="vishistory.top.content" />
                    </Typography>
                </div>
            </div>

            {/*  <p>MultiUrl:{JSON.stringify(multiUrl)}, tests.length:{tests.length}, location:{location}, protocol:{protocol}</p> */}

            <div id="filter" className={classes.filter}  >

                <div className={classes.filterHeader}>
                    <FormattedMessage id="vishistory.filter.title" />
                </div>

                <LocationSelect
                    locations={() => constants.LOCATIONS_VISCHECK}
                    all="true"
                    defaultValue={constants.SELECT_ALL_INTL}
                    changed={(event) => filterHandler('location', event)}
                    size="small"
                    style={{ width: "150px" }}
                />

                <OCSelect
                    id="protocol"
                    label="protocol"
                    defaultValue={constants.SELECT_ALL_INTL}
                    size="small"
                    style={{ width: "100px" }}
                    onChange={event => filterHandler('protocol', event)} >
                    <MenuItem key="protocolAll" value={constants.SELECT_ALL_INTL}>
                        <FormattedMessage id={constants.SELECT_ALL_INTL} />
                    </MenuItem>
                    <MenuItem key="http" value="http">http</MenuItem>
                    <MenuItem key="https" value="https">https</MenuItem>
                </OCSelect>

                <OCAutoComplete
                    label={t("vishistory.filter.url.label")}
                    placeholder={t("vishistory.filter.url.placeholder")}
                    items={multiUrlItems}
                    size='small'
                    containerstyle={{ width: '450px' }}
                    onChange={(event, value, reason) => autocompleteChangeHandler('multiUrl', event, value, reason)} />

            </div>

            <div className={classes.filterDetail}>
                <Typography variant={calcVariant("subtitle1")}>
                    <FormattedMessage id="vishistory.filter.detail" values={{
                        itemnumber: tests.length
                    }} />
                </Typography>
            </div>

            {
                options.categories.length > 0 &&
                <div id="container" className={classes.chartContainer} style={{ height: chartHeight }}>
                    <OCStackedColumnChart
                        title={t("vishistory.chart.title")}
                        subtitle={t("vishistory.chart.subtitle")}
                        plotOptions={options.plotOptions}
                        series={options.series}
                        categories={options.categories}
                        chartHeight={chartHeight}
                    />
                </div>
            }

            <div className={classes.content}>

                <TableContainer className={classes.historyDataTable} variant="dense">
                    <Table className={classes.table} aria-label="simple table" size="small">
                        <TableHead className={classes.tableHead}>
                            <TableRow hover={true} selected={true}>
                                <StyledTableCell align="center">
                                    <Typography variant={scoreVrnt}>
                                        <FormattedMessage id="table.header.url" />
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Typography variant={scoreVrnt}>
                                        <FormattedMessage id="table.header.location" />
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Typography variant={scoreVrnt}>
                                        <FormattedMessage id="table.header.date" />
                                    </Typography>
                                </StyledTableCell>

                                <StyledTableCell align="right">
                                    <Typography variant="caption">
                                        <FormattedMessage id="table.header.duration" />
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Typography variant="caption">
                                        <FormattedMessage id="table.header.transfersize" />
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Typography variant="caption">
                                        <FormattedMessage id="table.header.count" />
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Typography variant={scoreVrnt}></Typography>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.tableBody}>
                            {tests.map((test) => (
                                <StyledTableRow key={test.filename} className={classes.tableRow} style={utils.testErrorStyle(test, theme)}>
                                    <StyledTableCell align="center" component="th" scope="row">
                                        {calcUrlText(test)}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Typography variant={calcVariant("body2")}>{intlLocationMap.get(test.location)}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Typography variant={calcVariant("body2")}>
                                            <DateText date={test.date} month="2-digit" />
                                        </Typography>
                                    </StyledTableCell>

                                    { scoresTableCells(test)}

                                    <StyledTableCell align="center">
                                        <div>
                                            <Tooltip title={t("button.delete.tooltip")}>
                                                <IconButton color="primary" onClick={event => deleteIconClickedHandler(test, event)}>
                                                    <DeleteForeverOutlinedIcon color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </StyledTableCell>

                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <div>
                <OCDialog
                    type={constants.COMPONENT_OCDIALOG_TYPE_CONFIRMCANCEL}
                    confirmHandler={event => deleteHandler(dialogTest, event)}
                    handleClose={() => setDialogOpen(false)}
                    dialogOpen={dialogOpen}
                    dialogTitle={t("vishistory.delete.dialog.title")}
                    dialogContent={t("vishistory.delete.dialog.content")}
                />
            </div>

        </div>
    );
};

export default VisHistory;