import React, { useState, createContext } from "react";
import { IntlProvider } from 'react-intl';
import messages_en from "../translations/en.json";
import messages_de from "../translations/de.json";
import messages_tr from "../translations/tr.json";
import messages_nl from "../translations/nl.json";
import messages_fr from "../translations/fr.json";

export const locale_en = 'en';
export const locale_de = 'de';
export const locale_tr = 'tr';
export const locale_nl = 'nl';
export const locale_fr = 'fr';

const browserLocale =
    (navigator.languages && navigator.languages[0])
    || navigator.language || navigator.userLanguage ||
    'unknown';

const messages = {};
messages[locale_en] = messages_en;
messages[locale_de] = messages_de;
messages[locale_tr] = messages_tr;
messages[locale_nl] = messages_nl;
messages[locale_fr] = messages_fr;

const LocaleContext = createContext({
    locale: '',
    switchToEN: () => { },
    switchToDE: () => { },
    switchToTR: () => { },
    switchToNL: () => { },
    switchToFR: () => { }
});
export default LocaleContext;


export const LocaleContextProvider = props => {


    const defaultLocale = Array.from(Object.keys(messages))
        .reduce( (acc, currVal) => {
            if (browserLocale.startsWith(currVal))
                acc = currVal;
            return acc;
        }, locale_nl );
    

    const [locale, setLocale] = useState(defaultLocale);


    const switchToEN = () => {
        setLocale(locale_en);
    };
    const switchToDE = () => {
        setLocale(locale_de);
    };
    const switchToTR = () => {
        setLocale(locale_tr);
    };
    const switchToNL = () => {
        setLocale(locale_nl);
    };
    const switchToFR = () => {
        setLocale(locale_fr);
    };

    // alert("Locale sıfırlanacak locale:" + locale);
    // console.log("Locale sıfırlanacak browser locale:" + browserLocale, defaultLocale, messages[locale]);

    return (
        <LocaleContext.Provider value={{
            locale: locale,
            switchToEN: switchToEN,
            switchToDE: switchToDE,
            switchToTR: switchToTR,
            switchToNL: switchToNL,
            switchToFR: switchToFR
        }}>
            <IntlProvider locale={locale} messages={messages[locale]}>
                {props.children}
            </IntlProvider>
        </LocaleContext.Provider>
    );
};