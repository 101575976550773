import React, { useState, useEffect, useContext } from 'react';
import useHttp from '../../hooks/http';
import { ST_SEND, ST_ERROR, ST_RESPONSE } from '../../hooks/http';


import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { FormControlLabel, Switch, useMediaQuery } from "@material-ui/core";

import { OCButton, OCDetailedMuiAlert } from '../../common/OCComponents';
import * as constants from '../../common/Constants';
import * as lsUtils from '../../common/LSUtils';
import { useIntl } from 'react-intl';
import { LocationSelect, UrlText } from '../../common/InputUtils';
import LocaleContext from '../../context/LocaleContext';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        height: "100%",
        // border: "3px solid red"

    },
    form: {
        height: "100%",
        maxWidth: "900px",
        // border: "2px solid red"
    },

    formRow: {
        minHeight: "80px",
        // border: "1px solid black"
    },
    formFooter: {
        // border: "2px solid red"
    },

    switchLabel: {
        background: 'rgba(255,255,255, 0.9)',
        margin: 0,
        borderRadius: '12px',
        '& .MuiTypography-body1': {
            fontWeight: 'bolder',
            lineHeight: '1.1876em',
            letterSpacing: '0.00938em',
            fontSize: '0.75rem',
        }
    },

    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const initTest = (data, reqExtra) => {
    // reqEXtra => { url: (secured ? 'https://' : 'http://') + url, location: 'frankfurt' };
    const tests = lsUtils.loadTestsFromLocalStorage(constants.LS_HEALTH);
    data["historyIndex"] = tests.length;
    tests.push({ filename: data.filename, url: data.url, date: new Date(), location: reqExtra.location });
    localStorage.setItem(constants.LS_HEALTH, JSON.stringify(tests));
};

const HealthCheck = props => {
    const classes = useStyles();
    const { formatMessage: f } = useIntl();
    const ctx = useContext(LocaleContext);
    const miniScreen = useMediaQuery('(max-width:400px)');

    const [url, setUrl] = useState('');
    const [location, setLocation] = useState(constants.LOCATIONS_HEALTHCHECK[0].key);

    const [formIsValid, setFormIsValid] = useState(false);
    const [secured, setSecured] = useState(false);

    const {
        st,
        data,
        sendRequest,
        reqExtra,
        reqIdentifier,
        clear
    } = useHttp();

    const buttonClassname = clsx({
        [classes.buttonSuccess]: (st === ST_RESPONSE),
    });

    const { reportHandler } = props;
    useEffect(() => {

        if (st === ST_RESPONSE && reqIdentifier === 'HEALTH_URL') {
            initTest(data, reqExtra);

            console.log("data with historyIndex?!! ", data);
            reportHandler(data);
        }

    }, [st, data, reqIdentifier, reportHandler, reqExtra]);

    useEffect(() => {
        const urlValid = url.trim() !== '';

        if (formIsValid !== urlValid)
            setFormIsValid(urlValid);
    }, [url, formIsValid]);

    const checkHandler = event => {
        event.preventDefault();

        const formData = { 
            url: (secured ? 'https://' : 'http://') + url, 
            location: location, 
            locale: ctx.locale,
            ...constants.URL_HEALTH_CHECK_REQ 
        };

        sendRequest(
            props.checkUrl,
            'POST',
            formData,
            'HEALTH_URL',
            formData
        );
    };

    let errorModalContent = null;
    if (st === ST_ERROR) {

        errorModalContent = (

            <OCDetailedMuiAlert
                severity="error"
                onClose={clear}
                title='Health Check'
            >{'Error for url '}<strong>{(reqExtra ? reqExtra.url : '')}</strong>
            </OCDetailedMuiAlert>
        );
    }

    const protocol = secured ? 'https://' : 'http://';
    const loading = (st === ST_SEND);

    const setXs = (miniSize, normalSize) => {
        return miniScreen ? miniSize : normalSize;
    };

    return (
        <React.Fragment>
            {errorModalContent}

            {/* <p style={{ color: 'white' }}>{JSON.stringify(data)}</p> */}

            <Grid container className={classes.root} justify="space-between" direction="column">

                <Grid container className={classes.form} direction="column" justify="space-evenly" alignItems="flex-start" wrap="nowrap">
                    {/* <p> formIsValid: {formIsValid ? 'true' : 'false'}, url: {url}, location: {location}</p> */}

                    <Grid container item className={classes.formRow}
                        fullwidth="true"
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        wrap='wrap'>

                        <Grid container item xs={setXs(12, 10)} fullwidth="true">

                            <UrlText
                                protocol={protocol}
                                changed={(event) => setUrl(event.target.value)}
                            />

                        </Grid>
                        <Grid container item xs={1}>

                            <FormControlLabel classes={{ root: classes.switchLabel }}
                                control={
                                    <Switch
                                        checked={secured}
                                        onChange={() => setSecured(!secured)}
                                        name="secured"
                                        color="primary"
                                    />
                                }
                                label="https"
                                labelPlacement="top"
                            />
                        </Grid>

                    </Grid>

                    <Grid container item className={classes.formRow} style={{ maxWidth: '250px' }}>
                        <LocationSelect
                            locations={() => constants.LOCATIONS_HEALTHCHECK}
                            changed={(event) => setLocation(event.target.value) }
                            defaultValue={location}
                            required
                            style={{ minWidth: '200px' }}
                        />
                    </Grid>

                    <Grid container className={classes.formFooter} justify="flex-end" alignItems="flex-end">
                        <Grid item>
                            {
                                <div className={classes.wrapper}>
                                    <OCButton
                                        className={buttonClassname}
                                        disabled={loading || !formIsValid}
                                        onClick={checkHandler}

                                    >{f({ id: "button.start" })}
                                    </OCButton>
                                    {loading && <CircularProgress size={48} className={classes.buttonProgress} />}
                                </div>
                            }
                        </Grid>

                    </Grid>


                </Grid>


            </Grid>
        </React.Fragment >
    );
};

export default HealthCheck;








