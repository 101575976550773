import React from 'react';
import PropTypes from 'prop-types';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import networkgraph from "highcharts/modules/networkgraph";
import * as constants from '../../Constants';
import { useIntl } from 'react-intl';
networkgraph(Highcharts);

export const graphTypes = { root: 0, leaf: 99, parser: 11, script: 12, others: 19, invalid: 89 };
export const graphSizes = { small: 's', normal: 'n', large: 'l' };
export const graphColors = [
  '#f3583a',
  '#e48a3d',
  '#9bf102',
  '#026490',
  '#f314df',
  '#afa223',
  '#c9b99a',
  '#468536',
  '#6595d2',
  '#833f6e',
  '#0d51d1',
  '#9f0035',
  '#5c5551',
  '#26f916',
  "#7cb5ec",
  "#1a1ac4",
  "#90ed7d",
  "#f7a35c",
  "#8085e9",
  "#f15c80",
  "#e4d354",
  "#2b908f",
  "#f45b5b",
  "#91e8e1",
  '#58698c',
  '#c85176',
];

export const getGraphNodeType = (initiatorType) => {
  if (initiatorType) {

    switch (initiatorType) {
      case 'parser':
        return graphTypes.parser;
      case 'script':
        return graphTypes.script;
      default:
        return graphTypes.others;
    }
  }
  else
    return graphTypes.invalid;
}

const getSize = (size) => {
  return Object.values(graphSizes).reduce(

    (acc, currVal) => {

      if (currVal === size)
        acc = size;

      return acc;
    },
    graphSizes.normal);
};

/**************************************  
        //sourceData === data has the format shown below;

            [   { id:'1',   parentId: '0', type:0,  level: 0, url:'..<myUrl>..', parentUrl: null, score:{ children:?, myValue:?, childrenTotalValue:? } },  //root node

                { id:'1.2', parentId: '1', type:11, level: 1, url:'..<myUrl>..',  parentUrl: '..<parentUrl>...', score:{ children:?, myValue:?, childrenTotalValue:? } }, //branch nodes
                { id:'1.3', parentId: '1', type:11, level: 1, url:'..<myUrl>..',  parentUrl: '..<parentUrl>...', score:{ children:?, myValue:?, childrenTotalValue:? } }, //branch nodes
                ...

                { . ...                    type:99 } //leaf node
                { . ...                    type:99 } //leaf node
                { . ...                    type:99 } //leaf node
                ...
            ]

        // id: 1 => This is the root element. type is 0, initiatorType='other' for this element and as no parent exists, parentUrl = null
        // For the others, parentId has the value of the 'id' for the parent item. Also parentUrl has the same values for
        //   all items of the same parent.

        // Normally we will use all the type items but for now, we skip all but 'other' and 'parser'. 

        // So; to convert this data to HighCharts grap seriesData, we will use all the items but the root, 
        // and map it as [ parentUrl, url ] . 

        // Sample graphData is shown below; ( series => data)
            graphData = [
                ['Proto Indo-European', 'Balto-Slavic'],
                ['Proto Indo-European', 'Germanic'],
                
                ['Germanic', 'North Germanic'],
                ['Germanic', 'West Germanic'],
                ['Germanic', 'East Germanic'],
                ['North Germanic', 'Old Norse'],
                ['North Germanic', 'Old Swedish'],
                ['North Germanic', 'Old Danish'],
               ,
                ['Balto-Slavic', 'Baltic'],
                ['Balto-Slavic', 'Slavic'],
                ['Slavic', 'East Slavic'],
                
                // Leaves:             
               
                ['East Slavic', 'Bulgarian'],
               
                ['East Slavic', 'Serbo-Croatian'],
                ['East Slavic', 'Slovene'],

            ];
          
/**************************************  */

const OCGraph = props => {

  const intl = useIntl();

  const { sourceDataObj, title, subtitle } = props;
  console.log('OCGraph.render', props);
  const { dataMap, rootIdArr } = sourceDataObj;

  const graphSize = getSize(props.size);
  const rootObj = rootIdArr.map(rootId => dataMap.get(rootId)).reduce((acc, currVal) => {
    if (acc.childrenIds.length < currVal.childrenIds.length)
      acc = currVal;

    return acc;
  });

  console.log('root obj', rootObj);

  const radius = (score, intervalTable) => {

    const acc =
      intervalTable.reduce((acc, currVal) => {

        if (!acc.done) {
          if (currVal.score >= score) {
            // Done
            acc = {
              ...acc,
              done: true,
              score2: currVal.score,
              radius2: currVal.radius,
            };

          } else {
            //Update acc values
            acc = {
              ...acc,
              score1: currVal.score,
              radius1: currVal.radius
            };
          }
        }
        return acc;
      }, { done: false, score1: null, score2: null, radius1: null, radius2: null })

    // const mul = (acc.radius2 - acc.radius1) / (acc.score2 - acc.score1);
    const result =
      Math.min(
        Math.max(
          (((score - acc.score1) * ((acc.radius2 - acc.radius1) / (acc.score2 - acc.score1))) + acc.radius1),
          acc.radius1
        ),
        acc.radius2);

    return Math.max(4, (result + constants.GRAPH_RADIUS_DECREMENTER[graphSize]));
  }

  const rootColor = '#ff0000';

  const lvl1parentId = (id, level = 1) => {
    const arr = id.split('.');
    let result = arr[0];
    for (let k = 1; k <= level; k++) {
      result = result.concat('.').concat(arr[k]);
    }
    return result;
  }

  //"Süre:{duration} Çap:{radius} Seviye:{level} AğİstekSayısı:{count} TransferBoyutu:{transfersize} (id:{id})",
  const toolTip = (d, radius) => {
    return intl.formatMessage(
      { id: "vishistory.node.tooltip" },
      {
        duration: d.scores.duration.total,
        radius: radius,
        level: d.level,
        count: d.scores.count.total,
        transfersize: d.scores.transferSize.total,
        id: d.id
      }
    );
  };

  const createSeriesDataAndNodes = (dataMap, rootObj) => {

    const maxScore = rootObj.scores.duration.total;
    const radiusIntervals = [
      { score: -1, radius: 4 },
      { score: maxScore / 16, radius: 28 },
      { score: maxScore / 8, radius: 36 },
      { score: maxScore / 3, radius: 44 },
      { score: maxScore + 1, radius: 48 }
    ];


    /* 'Duration:' + d.scores.duration.total.toFixed(0) +
            ' Radius:' + rad.toFixed(2) +
            ' Level:' + d.level +
            ' Count:' + d.scores.count.total +
            ' TransferSize:' + d.scores.transferSize.total.toFixed(0) +
            ' (id:' + d.id + ')' */

    const nodes = Array.from(dataMap.values())
      .map(d => {

        const rad = radius(d.scores.duration.total, radiusIntervals);

        return {
          id: d.id,
          name: d.url.url + '<br/>' + toolTip( d, rad ),
          domain: d.url.domain.main,
          level: d.level,
          duration: d.scores.duration.total.toFixed(0),
          transfer: d.scores.transferSize.total.toFixed(0),
          count: d.scores.count.total.toFixed(0),
          marker: {
            radius: rad,
          }
        };
      });
    const data = Array.from(dataMap.values())
      .filter(d => d.type !== graphTypes.root)
      .map(
        d => {
          return {
            to: d.id,
            from: d.parentId,
          }
        });

    //Color configuration
    const colorMap = new Map();
    const lvl1Nodes = nodes.filter(n => n.level === 1);
    lvl1Nodes.forEach((n, index) => colorMap.set(n.id, (index++ % graphColors.length)));

    //Update colors according to the parent node on level=1 (root level=0)
    nodes.forEach(node => {
      if (node.level < 1)
        node.color = rootColor;
      else
        node.color = graphColors[colorMap.get(lvl1parentId(node.id))];
    });

    return { data: data, nodes: nodes };
  }

  let graphSizeSubTitlePostfix = '';
  /* if (graphSize === graphSizes.large)
    graphSizeSubTitlePostfix = ' (Large Display Mode)';
  else if (graphSize === graphSizes.small)
    graphSizeSubTitlePostfix = ' (Mini mode)'; */


  const options = {

    //Chart
    chart: {
      type: 'networkgraph',
      backgroundColor: 'rgba(255,255,255, 0.7)',
      borderRadius: 20,

      padding: [50, 50, 50, 50],
      margin: [80, 10, 20, 10]
    },

    title: {
      text: title
    },
    subtitle: {
      text: subtitle + graphSizeSubTitlePostfix
    },
    credits: {
      href: null,
      text: '',
    },
    tooltip: {
      backgroundColor: '#000000',
      borderRadius: 20,
      style: {
        color: '#f0ffff'
      },
    },

    //PlotOptions
    plotOptions: {
      networkgraph: {
        layoutAlgorithm: {
          enableSimulation: true,
          gravitationalConstant: 0,
          // friction: -0.8,
        },
      }
    },

    //Series
    series: [
      {
        name: 'Domain Health',
        link: {
          width: 2
        },
        dataLabels: {
          enabled: true,
          linkFormat: '',//'Score:<b>{point.toNode.duration}</b>',
          //linkFormat: '{point.toNode.count} ; {point.toNode.transfer} ; <b>{point.toNode.duration}</b>',
          format: '{point.domain}'
        },

        //data:[] , nodes:[]
        ...createSeriesDataAndNodes(dataMap, rootObj),

      }
    ], // END - Series

  }; // END

  return (

    <HighchartsReact
      containerProps={{ style: { height: "100%", width: '100%' } }}
      highcharts={Highcharts}
      options={options}
    />

  );



};

OCGraph.propTypes = {

};

export default OCGraph;

