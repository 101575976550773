import { useEffect, useRef } from 'react';

const useInterval = (callback, delay) => {

    const savedCallback = useRef();

    //Remember the latest callback
    useEffect( () => {
        savedCallback.current = callback;
    }, [callback]);

    //Setup the interval
    useEffect( ()=> {
        let id = setInterval( () => {
            savedCallback.current();
        }, delay);

        return () => clearInterval(id);
    }, [delay]);

};

export default useInterval;